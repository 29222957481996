import React from 'react';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CodeIcon from '@material-ui/icons/Code';
import DvrIcon from '@material-ui/icons/Dvr';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import ListItemLink from '../ListItemLink';

function AdminSidebar() {
  return (
    <div className="sidebar-wrapper">
      <List disablePadding dense>
        <ListItemLink to={'/admin/lender'}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText>Lenders</ListItemText>
        </ListItemLink>
        <ListItemLink to={'/admin/loanlimit'}>
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText>Loan limit</ListItemText>
        </ListItemLink>
        <ListItemLink to={'/admin/zipcode'}>
          <ListItemIcon>
            <CodeIcon />
          </ListItemIcon>
          <ListItemText>Zipcode</ListItemText>
        </ListItemLink>
        <ListItemLink to={'/admin/testing'}>
          <ListItemIcon>
            <DvrIcon />
          </ListItemIcon>
          <ListItemText>Testing</ListItemText>
        </ListItemLink>
      </List>
    </div>
  );
}
  
export default AdminSidebar;