import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { getAllLenderPublished } from '../../actions';

const style = {
  root: {
    paddingBottom: 5
  }
};

class LenderAutocomplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.onChangeLender = this.onChangeLender.bind(this);
  }

  componentDidMount() {
    this.props.getAllLenderPublished();
  }

  onChangeLender(selected) {
    const lenderSelected = selected.map(x => x._id);
    if(lenderSelected) {
      this.props.onLenderFetched(lenderSelected);
    }
  }

  renderLenderAutocomplete() {
    const { classes, label, placeholder } = this.props;
    let result = '';
    const lenders = this.props.allPublisherLender;
    if(lenders && lenders.length) {
      result = (
        <Autocomplete
          multiple
          id="lender"
          size="small"
          options={(lenders) || []}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValues) => this.onChangeLender(newValues)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                size="small"
                label={option.name} {...getTagProps({ index })} 
              />
            ))
          }
          className={classes.root}
          renderInput={(params) => (
            <TextField {...params} size="small" variant="outlined" label={label} placeholder={placeholder} />
          )}
        />
      );
    }
    return result;
  }
  
  render() {
    return this.renderLenderAutocomplete(this.props);
  }
}

const mapStateToProps = state => {
  return {
    allPublisherLender: state.lender.allPublisherLender,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLenderPublished: () => dispatch(getAllLenderPublished())
  };
};

LenderAutocomplete.propTypes = {
  classes: PropTypes.object,
  allPublisherLender: PropTypes.array,
  getAllLenderPublished: PropTypes.func,
  onChange: PropTypes.func,
  onLenderFetched: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(LenderAutocomplete));

