import React from 'react';
import { Form } from 'react-bootstrap';

class LoanAmountInput extends React.Component {
  
  renderLoanAmountInput(props) {
    let result;
    result = (
      <Form.Group controlId="formLoanAmount">
        <Form.Label>Loan Amount</Form.Label>
        <Form.Control size="sm" type="text" {...props}/>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLoanAmountInput(this.props);
  }
}


export default LoanAmountInput;

