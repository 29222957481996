import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MuiAlert from '@material-ui/lab/Alert';
import './style.scss';

import { getRateResults } from './../../actions';
import MortgageResultTable from './ResultTable';

const style = ({
  alert: {
    marginBottom: 24,
  },
});

class MortgageResult extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.assumptions !== this.props.assumptions) {
      this.props.getRateResults(this.props.assumptions);
    }
  }

  renderMortgageResult = () => {
    let result;
    const { classes } = this.props;
    if(this.props.rateResult && Object.keys(this.props.rateResult).length > 0) {
      const rateResult = this.props.rateResult;
      const loanTerm = rateResult.loanTerm;
      const lockDays = `${(rateResult.lockDays) ? rateResult.lockDays : 30}  Day Lock`;
      const programs = rateResult.programs;
      const ltv = rateResult.ltv;
      const messages = rateResult.messages;
      let alertMessage;
      if (messages && messages.length) {
        alertMessage = messages.map((message, index) => {
          const messageLender = (message.lender) || '';
          const messageType = (message.type) ? message.type.toLowerCase() : 'error';
          const messageText = (message.message) || '';
          return (<MuiAlert key={index} elevation={0} variant="standard" severity={messageType} className={classes.alert}>{messageLender}: {messageText}</MuiAlert>);
        });
      }
      result = (
        <div>
          {alertMessage}
          {programs.map((program, programIndex) => {
            const programType = program.type;
            const loanTermText = `${loanTerm} ${programType}`;
            const rates = program.rates;
            return (
              <Card key={programIndex} elevation={0} className="mortgage-result-wrapper">
                <CardHeader 
                  className="result-header"
                  title={
                    <h5 className="title">{loanTermText}</h5>
                  }
                  subheader={
                    <label className="day-lock">{lockDays}</label>
                  }
                />
                <CardContent className="result-content">
                  <Box className="mortgage-result-container">
                    <Paper elevation={0} className="mortgage-result">
                      <MortgageResultTable rates={rates} loanTerm={loanTermText} lockDays={lockDays} ltv={ltv} />
                    </Paper>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </div>
      );
    } else {
      result = '';
    }
    return result;
  }

  render() {
    return this.renderMortgageResult();
  }
}

const mapStateToProps = state => {
  return {
    rateResult: state.rate.result,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateResults: (assumptions) => dispatch(getRateResults(assumptions)),
  };
};

MortgageResult.propTypes = {
  classes: PropTypes.object,
  assumptions: PropTypes.object,
  rateResult: PropTypes.object,
  getRateResults: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(MortgageResult));
