import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Form, Button } from 'react-bootstrap';
import MuiAlert from '@material-ui/lab/Alert';
import { login } from './../../actions';
// import { Link } from 'react-router-dom';

const style = ({
  alert: {
    marginBottom: 24,
  },
});

class SignInForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      validated: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    const { username, password } = this.state;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({ validated: true });
    } else {
      this.props.login(username, password);
    }
  }

  renderSignInForm() {
    let result;
    const { classes } = this.props;
    const { message } = this.props;
    result = (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        { (message) ? <MuiAlert elevation={0} variant="standard" severity="error" className={classes.alert}>{message}</MuiAlert> : null}
        <Form.Group controlId="formSignInUsername">
          <Form.Control type="text" placeholder="Username or Email" size="sm" name="username" onChange={this.handleChange} required/>
          <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formSignInPassword">
          <Form.Control type="password" placeholder="Password" size="sm" name="password" onChange={this.handleChange} required/>
          <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formSignInSubmit">
          <Button variant="primary" type="submit" size="sm" block>
            Sign In
          </Button>
        </Form.Group>
        {/* <ul className="link-wrapper text-center">
          <li className="link"><Link to="/portal/signup">Sign Up</Link></li>
          <li className="link">|</li>
          <li className="link"><Link to="#">Reset Password</Link></li>
        </ul> */}
      </Form>
    );
    return result;
  }

  render() {
    return this.renderSignInForm();
  }
}

const mapStateToProps = state => {
  return {
    message: state.authenticate.message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(login(username, password)),
  };
};

SignInForm.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string,
  login: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(SignInForm));
