import {
  PARSERATEFILE_SUCCESS,
  PARSERATEFILE_FAILURE,
  GET_PARSEDRATETABLES,
  GET_PARSEDADJUSTMENTTABLES,
} from '../actions/types';

const INITIAL_STATE = {
  parseRateFileResult: {},
  parseRateFileMessage: '',
  parsedRateTables: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PARSERATEFILE_SUCCESS:
      return {
        ...state,
        parseRateFileResult: action.data
      };
    case PARSERATEFILE_FAILURE:
      return {
        ...state,
        parseRateFileMessage: action.data
      };
    case GET_PARSEDRATETABLES:
      return {
        ...state,
        parsedRateTables: action.data
      };
    case GET_PARSEDADJUSTMENTTABLES:
      return {
        ...state,
        parsedAdjustmentTables: action.data
      };
    default:
      return state;
  }
};