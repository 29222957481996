import React from 'react';
import { Form } from 'react-bootstrap';

class CashOutSelectBox extends React.Component {
  
  renderCashOutSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formCashOut">
        <Form.Label>Cash Out</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          {...props}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderCashOutSelectBox(this.props);
  }
}


export default CashOutSelectBox;

