import {
  GET_RATETESTINGS,
  GET_RATETESTING,
  GET_TESTSUITE,
  RUN_RATETESTING,
} from '../actions/types';

const INITIAL_STATE = {
  rateTestings: [],
  rateTesting: {},
  rateTestSuites: [],
  rateTestingResults: {},
  rateCreateTestCase: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RATETESTINGS:
      return {
        ...state,
        rateTestings: action.data
      };
    case GET_RATETESTING:
      return {
        ...state,
        rateTesting: action.data
      };
    case GET_TESTSUITE:
      return {
        ...state,
        rateTestSuites: action.data
      };
    case RUN_RATETESTING:
      return {
        ...state,
        rateTestingResults: action.data
      };
    default:
      return state;
  }
};