import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import rootReducer from './reducers/index';

import 'bootstrap/dist/css/bootstrap.css';
import './style.scss';
import App from './components/App';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    fontSize: 8.75,
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#35d7a9',
      dark: '#1eba8e',
    },
  },
  overrides: {
    MuiTable: {
      root: {
        marginTop: 10
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
      }
    },
    MuiTablePagination: {
      root: {
        fontSize: 13,
      }
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        marginRight: 5,
        marginLeft: 5
      }
    },
    MuiDialogContent: {
      root: {
        padding: 10,
        '&:first-child': {
          paddingTop: 10,
        },
      }
    },
    MuiDialogActions: {
      root: {
        padding: '0 10px 10px',
        justifyContent: 'flex-start'
      }
    },
    MuiCardHeader: {
      root: {
        padding: '10px 16px',
      }
    }
  }
});

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}> <MuiThemeProvider theme={theme}> <App/> </MuiThemeProvider> </Provider>,
  document.getElementById('wonderate-wrapper')
);

serviceWorker.unregister();