import React from 'react';
import { Form } from 'react-bootstrap';

class CreditScoreInput extends React.Component {
  
  renderCreditScoreInput(props) {
    let result;
    result = (
      <Form.Group controlId="formCreditScore">
        <Form.Label>Credit Score</Form.Label>
        <Form.Control size="sm" type="text" {...props}/>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderCreditScoreInput(this.props);
  }
}


export default CreditScoreInput;

