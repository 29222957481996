import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { getTestSuites } from '../../actions';
class TestSuiteSelectBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getTestSuites();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.testSuites !== this.props.testSuites) {
      const testSuites = (this.props.testSuites.length) ? this.props.testSuites : [];
      if(testSuites.length) {
        const defaultValue = testSuites[0]._id;
        this.props.getDefaultValue(defaultValue);
        this.setState({
          value: defaultValue
        });
      }
    }
  }

  handleChange(event){
    const selectedValue = event.target.value;
    this.setState({
      value: selectedValue
    });
    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  }

  renderTestSuiteSelectBox() {
    let result;
    if(this.props.testSuites) {
      const testSuites = (this.props.testSuites) ? this.props.testSuites : [];
      result = (
        <Form.Group controlId={`form-value-type-${Date.now()}`}>
          <Form.Label>Test suite</Form.Label>
          <Form.Control 
            size="sm"
            as="select"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {(testSuites.length) ? testSuites.map((testSuite, index) => (
              <option key={index} value={testSuite._id}>{testSuite.name}</option>
            )) : <option></option>}
          </Form.Control>
        </Form.Group>
      );
    }
    return result;
  }

  render() {
    return this.renderTestSuiteSelectBox(this.props);
  }
}

const mapStateToProps = state => {
  return {
    testSuites: state.testing.rateTestSuites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTestSuites: () => dispatch(getTestSuites())
  };
};

TestSuiteSelectBox.propTypes = {
  testSuites: PropTypes.array,
  getTestSuites: PropTypes.func,
  onChange: PropTypes.func,
  getDefaultValue: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestSuiteSelectBox));

