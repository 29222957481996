// alert
export const SHOW_ALERT = 'SHOW_ALERT';

// spinner
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_DIALOG_SPINNER = 'SHOW_DIALOG_SPINNER';
export const HIDE_DIALOG_SPINNER = 'HIDE_DIALOG_SPINNER';

// loan limits
export const GET_LOANLIMITS = 'GET_LOANLIMITS';

// zip code & state code
export const GET_ZIPSTATECODES = 'GET_ZIPSTATECODES';

// lenders
export const GET_LENDER = 'GET_LENDER';
export const GET_LENDERS = 'GET_LENDERS';
export const GET_ALL_PUBLISHED_LENDERS = 'GET_ALL_PUBLISHED_LENDERS';
export const GET_ALLLENDER = 'GET_ALLLENDER';
export const GET_RATEOFLENDER = 'GET_RATEOFLENDER';
export const GET_ADJUSTMENTOFLENDER = 'GET_ADJUSTMENTOFLENDER';
export const UPDATE_PUBLISHED_SUCCESS = 'UPDATE_PUBLISHED_SUCCESS';
export const UPDATE_PUBLISHED_FAILURE = 'UPDATE_PUBLISHED_FAILURE';

// rate
export const GET_RATE = 'GET_RATE';
export const GET_RATERESULT = 'GET_RATERESULT';

// adjustment
export const GET_ADJUSTMENT = 'GET_ADJUSTMENT';

// loan type
export const GET_LOANTYPES = 'GET_LOANTYPES';

// metadata
export const GET_CONDITIONMETADATA = 'GET_CONDITIONMETADATA';

// file
export const GET_RATEFILES = 'GET_RATEFILES';

// parsed rate
export const PARSERATEFILE_SUCCESS = 'PARSERATEFILE_SUCCESS';
export const PARSERATEFILE_FAILURE = 'PARSERATEFILE_FAILURE';
export const GET_PARSEDRATETABLES = 'GET_PARSEDRATETABLES';
export const GET_PARSEDADJUSTMENTTABLES = 'GET_PARSEDADJUSTMENTTABLES';

// testing
export const GET_RATETESTINGS = 'GET_RATETESTINGS';
export const GET_RATETESTING = 'GET_RATETESTING';
export const GET_TESTSUITE = 'GET_TESTSUITE';
export const RUN_RATETESTING = 'RUN_RATETESTING';

// authenticate
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

