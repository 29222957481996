import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MuiAlert from '@material-ui/lab/Alert';
import Spinner from '../../../../components/Shared/Spinner';
import MortgageResultTable from '../../../../components/MortgageResult/ResultTable';

const style = ({
  alert: {
    marginBottom: 24,
  },
});
class TestingRateDetails extends Component {

  renderRateResultDetails() {
    let result;
    const { classes } = this.props;
    const resultDetails = this.props.details;
    let loanTerm = resultDetails.loanTerm;
    const lockDays = `${(resultDetails.lockDays) ? resultDetails.lockDays : 30}  Day Lock`;
    const programs = resultDetails.programs;
    const ltv = resultDetails.ltv;
    const messages = resultDetails.messages;
    let alertMessage;
    if (messages && messages.length) {
      alertMessage = messages.map((message, index) => {
        const messageLender = (message.lender) || '';
        const messageType = (message.type) ? message.type.toLowerCase() : 'error';
        const messageText = (message.message) || '';
        return (<MuiAlert key={index} elevation={0} variant="standard" severity={messageType} className={classes.alert}>{messageLender}: {messageText}</MuiAlert>);
      });
    }
    result = (
      <div>
        {programs.map((program, programIndex) => {
          const programType = program.type;
          loanTerm = `${loanTerm} ${programType}`;
          const rates = program.rates;
          return (
            <Card key={programIndex} elevation={0} className="mortgage-result-wrapper">
              <CardHeader 
                className="result-header"
                title={
                  <h5 className="title">{loanTerm}</h5>
                }
                subheader={
                  <label className="day-lock">{lockDays}</label>
                }
              />
              <CardContent className="result-content">
                <Box className="mortgage-result-container">
                  <Paper elevation={0} className="mortgage-result">
                    {alertMessage}
                    <MortgageResultTable rates={rates} loanTerm={loanTerm} lockDays={lockDays} ltv={ltv} adjustmentDetailSize="md" />
                  </Paper>
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </div>
    );
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.props.loading ? <Spinner/> : null}
        {this.renderRateResultDetails()}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    loading: state.spinner.loadingDialog,
  };
};

TestingRateDetails.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  details: PropTypes.object,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, null)
)(withRouter(TestingRateDetails));

