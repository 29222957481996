import {
  SHOW_SPINNER,
  HIDE_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { FileService } from './../services';
import fileDownload from 'js-file-download';

export const downloadFile = (fileKey, fileName, type) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    FileService.downloadFile(fileKey, type).then((response) => {
      if (response) {
        dispatch({type: HIDE_SPINNER});
        fileDownload(new Blob([response]), fileName);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const deleteFile = (lenderId, fileKey, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    FileService.deleteFile(fileKey).then(response => {
      if (response.status === 200) {
        ownProps.getRateFilesOfLender(lenderId);
        dispatch({type: HIDE_SPINNER});
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};