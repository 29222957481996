import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './../Header';
import Spinner from './../../Shared/Spinner';
import './Portal.scss';
class PortalLayout extends React.Component {

  render() {
    return (
      <div className="portal-layout">
        <Header />
        <div className="portal-container">
          {this.props.children}
        </div>
        {this.props.loading ? <Spinner/> : null}
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loading,
  };
};

PortalLayout.propTypes = {
  loading: PropTypes.bool, 
  children: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, null)(PortalLayout));