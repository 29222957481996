import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import RateTableDetails from '../../containers/Admin/Rate/RateTableDetails';
import ConditionView from '../Condition/View';

const style = ({
  iconButton: {
    padding: 0,
  },
});

class RateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: (this.props.page) ? this.props.page : 0,
      rowsPerPage: (this.props.rowsPerPage) ? this.props.rowsPerPage : 50,
      openRateDetails: false,
      dialogSize: (this.props.dialogSize) ? this.props.dialogSize : 'xl',
      programId: '',
      orderBy: this.props.orderBy,
      order: this.props.order,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onClickOpenRateDetails = this.onClickOpenRateDetails.bind(this);
    this.handleCloseRateDetails = this.handleCloseRateDetails.bind(this);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.page !== this.props.page) {
      this.setState(prevProps => ({
        ...prevProps,
        page: this.props.page,
      }));
    }
    if(prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.setState(prevProps => ({
        ...prevProps,
        rowsPerPage: this.props.rowsPerPage,
      }));
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState(prevState => ({
      ...prevState,
      page: newPage
    }), () => this.props.onTableFetched(this.state));
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState(prevState => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }), () => this.props.onTableFetched(this.state));
  }

  onClickOpenRateDetails = (programId) => {
    this.setState(prevState => ({
      ...prevState,
      openRateDetails: true,
      programId
    }));
  }

  handleCloseRateDetails = () => {
    this.setState(prevState => ({
      ...prevState,
      openRateDetails: false,
    }));
  }

  createSortHandler = (property) => (event) =>  {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState(prevState => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }), () => this.props.onTableFetched(this.state));
  };

  renderRateTabe() {
    let result;
    if (this.props.rateTables) {
      const rateRows = this.props.rateTables.docs;
      const totalRows = this.props.rateTables.totalDocs;
      if(rateRows && totalRows > 0) {
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, totalRows.length - this.state.page * this.state.rowsPerPage);
        const columns = [
          { id: 'name', label: 'Name', sorting: true},
          { id: 'loan-type', label: 'Loan type', sorting: false},
          { id: 'program-type', label: 'Type', sorting: false},
          { id: 'condition', label: 'Condition', sorting: false},
          { id: 'action', label: 'View detail', align: 'center', sorting: false}
        ];
        result = (
          <div>
            <TableContainer component={Paper} square={true} elevation={0}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sorting ? <TableSortLabel
                          active={this.state.orderBy === column.id}
                          direction={this.state.orderBy === column.id ? this.state.order : 'asc'}
                          onClick={this.createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel> : column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rateRows.map((row) => {
                    return (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">{row.name}</TableCell>
                        <TableCell>{row.loanType}</TableCell>
                        <TableCell>{row.programType}</TableCell>
                        <TableCell><ConditionView condition={row.condition} /></TableCell>
                        <TableCell align="center">
                          <IconButton type="button" color="primary" className="btn-action" aria-label="View details" onClick={() => this.onClickOpenRateDetails(row._id)}>
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      count={totalRows}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Dialog
              open={this.state.openRateDetails}
              onClose={this.handleCloseRateDetails}
              fullWidth={true}
              maxWidth={this.state.dialogSize}
            >
              <DialogContent>
                <RateTableDetails programId={this.state.programId} />
              </DialogContent>
            </Dialog>
          </div>
        );
      }
    }
    return result;
  }
  
  render() {
    return this.renderRateTabe();
  }
}


RateTable.propTypes = {
  classes: PropTypes.object,
  rateTables: PropTypes.any,
  onTableFetched: PropTypes.func,
  dialogSize: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

export default withStyles(style)(RateTable);
