import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import { getRateTablesOfLender } from './../../../actions';
import LoanTypeSelectBox from '../../../components/Controls/SelectBoxLoanType';
import RateTable from '../../../components/Tables/RateTable';

const style = ({
  iconButton: {
    padding: 0,
  },
});

class RateTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lenderId: this.props.match.params.id,
      page: 0,
      rowsPerPage: 50,
      searchingKey: '',
      loanTypeId: '',
      orderBy: 'name',
      order: 'asc',
    };
    this.onSearchRate = this.onSearchRate.bind(this);
    this.onLoanTypeChange = this.onLoanTypeChange.bind(this);
    this.handleTableFetched = this.handleTableFetched.bind(this);
  }

  componentDidMount() {
    this.props.getRateTablesOfLender(this.state.lenderId, this.state.loanTypeId, this.state.searchingKey, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }

  onSearchRate = (event) => {
    this.setState({
      page: 0,
    });
    this.props.getRateTablesOfLender(this.state.lenderId, this.state.loanTypeId, this.state.searchingKey, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
    event.preventDefault();
  }

  onLoanTypeChange = (loanTypeId) => {
    this.setState({loanTypeId});
    this.props.getRateTablesOfLender(this.state.lenderId, loanTypeId, this.state.searchingKey, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }

  handleTableFetched = (fetchedState) => {
    this.setState({
      page: fetchedState.page,
      rowsPerPage: fetchedState.rowsPerPage,
      orderBy: fetchedState.orderBy,
      order: fetchedState.order,
    });
    this.props.getRateTablesOfLender(this.state.lenderId, this.state.loanTypeId, this.state.searchingKey, fetchedState.page + 1, fetchedState.rowsPerPage, fetchedState.orderBy, fetchedState.order);
  }

  renderSearching = () => {
    let result;
    if (this.props.rateTables && this.props.rateTables.totalDocs >= 0) {
      result = (
        <Paper onSubmit={this.onSearchRate} component="form" square={true} elevation={0}>
          <LoanTypeSelectBox onChange={this.onLoanTypeChange}/>
          <FormControl>
            <TextField
              label="Search"
              id="rate-search-input"
              value={this.state.searchingKey}
              onChange={ e => this.setState({searchingKey: e.target.value})}
            />
          </FormControl>
          <IconButton type="submit" aria-label="search" className="btn search">
            <SearchIcon />
          </IconButton>
        </Paper>
      );
    }
    return result;
  }

  renderRateTabe = () => {
    let result;
    if (this.props.rateTables && this.props.rateTables.totalDocs > 0) {
      result = (
        <RateTable 
          rateTables={this.props.rateTables}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          orderBy={this.state.orderBy}
          order={this.state.order}
          onTableFetched={this.handleTableFetched}
        />
      );
    }
    return result;
  }
  
  render() {
    return (
      <Fragment>
        {this.renderSearching()}
        {this.renderRateTabe()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    rateTables: state.lender.rateTables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateTablesOfLender: (
      lenderId,
      loanTypeId,
      searchingKey,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ) => dispatch(getRateTablesOfLender(
      lenderId,
      loanTypeId,
      searchingKey,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ))
  };
};

RateTableContainer.propTypes = {
  classes: PropTypes.object,
  rateTables: PropTypes.any,
  match: PropTypes.any,
  getRateTablesOfLender: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(RateTableContainer));
