import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ParseRateTableContainer from '../Rate/ParseRateTable';
import ParseAdjustmentTableContainer from '../Adjustment/ParseAdjustmentTable';

const style = {
  tabs: {
    borderBottom: '1px solid #CCC',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `lender-details-tab-${index}`,
    'aria-controls': `lender-details-tabpanel-${index}`,
  };
}

class ParseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
    this.onChangeTab = this.onChangeTab.bind(this);
  }
  
  onChangeTab(event, newValue) {
    this.setState({tabIndex: newValue});
  }

  getLenderDetailsTab() {
    const { classes } = this.props;
    const result = (
      <Paper square={true} elevation={0}>
        <Tabs
          value={this.state.tabIndex}
          onChange={this.onChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={ classes.tabs }
        >
          <Tab label="Rate Table" {...a11yProps(0)} />
          <Tab label="Adjustment Table" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={this.state.tabIndex} index={0}>
          <ParseRateTableContainer parsedId={this.props.parsedId} />
        </TabPanel>
        <TabPanel value={this.state.tabIndex} index={1}>
          <ParseAdjustmentTableContainer parsedId={this.props.parsedId} />
        </TabPanel>
      </Paper>
    );
    return result;
  }

  render() {
    return this.getLenderDetailsTab();
  }

}

ParseDetails.propTypes = {
  match: PropTypes.any,
  classes: PropTypes.object,
  parsedId: PropTypes.string,
};


export default withStyles(style)(ParseDetails);
