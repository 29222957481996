import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const AdjustmentService = {
  getAdjustmentDetails,
  createCustomAdjustment,
  updateCustomAdjustment,
  deleteCustomAdjustment,
};

function getAdjustmentDetails(adjustmentId, objectType = 'view') {
  return apiService.get(
    `v1/adjustment/${adjustmentId}/?objectType=${objectType}`,
    { headers: authHeader() }
  );
}

function createCustomAdjustment(adjustment, type) {
  return apiService.post('v1/adjustment/', { adjustment, type }, { headers: authHeader() });
}

function updateCustomAdjustment(adjustmentId, adjustment) {
  return apiService.put(`v1/adjustment/${adjustmentId}`, adjustment, { headers: authHeader() });
}

function deleteCustomAdjustment(adjustmentId, type, lenderId) {
  return apiService.post(`v1/adjustment/${adjustmentId}/delete`, { type, lenderId }, { headers: authHeader() });
}
