import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { runTestingRate, deleteRateTestCase } from '../../actions';
import TestingRateDetails from '../../containers/Admin/Testing/Rate/Details';
import TestingRateForm from '../../containers/Admin/Testing/Rate/Form';
import './TestingRateTable.scss';

const style = {
  runButton: {
    color: '#FFFFFF',
    boxShadow: 'none',
    margin: '0 2px',
  },
  dialogContent: {
    padding: 0,
  },
  iconButton: {
    padding: 0,
  }
};

class TestingRateTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogContentType: '',
      testCaseId: '',
      groupBy: 'name',
      expandedGroups: [],
      page: 0,
      rowsPerPage: 50,
      selected: [],
      numSelected: 0,
      openDialog: false,
      runTestParams: {
        lenderIds: this.props.selectedLenders,
        testIds: [],
      },
      resultDetails: {}
    };
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onClickRunTest = this.onClickRunTest.bind(this);
    this.handleLenderChange = this.handleLenderChange.bind(this);
    this.expandRow = this.expandRow.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.rateTestingResults !== this.props.rateTestingResults) {
      const rateTestingResults = this.props.rateTestingResults;
      const testingRateTables = this.props.testingRateTables;
      for (const testCaseId in rateTestingResults) {
        const rateIndexUpdate = testingRateTables.findIndex(rate => rate.testCaseId === testCaseId);
        this.props.testingRateTables[rateIndexUpdate].results = rateTestingResults[testCaseId];
      }
      this.forceUpdate();
    }
    if (prevState.runTestParams.lenderIds !== this.props.selectedLenders) {
      this.setState(prevState => ({
        ...prevState,
        runTestParams: {
          ...prevState.runTestParams,
          lenderIds: this.props.selectedLenders,
        }
      }));
    }
  }
  
  handleCloseDialog() {
    this.setState({
      openDialog: false,
    });
  }

  onClickOpenDialog(dialogContentType, dialogContent) {
    this.setState({
      openDialog: true,
      dialogContentType,
      resultDetails: (dialogContent && typeof (dialogContent) === 'object' && Object.keys(dialogContent).length) ? dialogContent : {}
    });
  }

  onClickRunTest(testId) {
    this.setState(prevState => ({
      runTestParams: {
        ...prevState.runTestParams,
        testIds: (testId) ? [testId] : []
      }
    }), 
    () => this.runTest()
    );
  }

  runTest() {
    this.props.runTestingRate(this.state.runTestParams);
  }

  onClickDelete(testSuiteId, testCaseId) {
    this.props.deleteRateTestCase(testSuiteId, testCaseId, this.props.parentProps);
  }

  onClickEdit(testCaseId) {
    this.setState(prevState => ({
      ...prevState,
      openDialog: true,
      dialogContentType: 'edit',
      testCaseId,
    }));
  }

  handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = this.props.testingRateTables.map((testCase, index) => index);
      this.setState({
        selected: newSelecteds,
        numSelected: newSelecteds.length
      });
      return;
    }
    this.setState({
      selected: [],
      numSelected: 0
    });
  }

  enhancedTableToolbar() {
    const { classes } = this.props;
    const result = (this.state.numSelected > 0) 
      ? (<Button 
        variant="contained" 
        className={classes.runButton} 
        size="small" 
        color="primary"
        onClick={() => this.onClickRunTest()}
      >
        Run All
      </Button>)
      : ('');
    return result;
  }

  handleLenderChange = (lenderIds) => {
    this.setState(prevState => ({
      runTestParams: {
        ...prevState.runTestParams,
        lenderIds
      }
    }));
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  }

  getGroupedData = rows => {
    const groupedData = rows.reduce((acc, item) => {
      let key = item[this.state.groupBy];
      let groupData = acc[key] || [];
      acc[key] = groupData.concat([item]);
      return acc;
    }, {});
    const expandedGroups = {};
    Object.keys(groupedData).forEach(item => {
      expandedGroups[item] = this.state.expandedGroups.indexOf(item) !== -1;
    });
    this.groups = expandedGroups;
    return groupedData;
  };

  expandRow = groupVal => {
    const curr = this.groups[groupVal];
    let expandedGroups = this.state.expandedGroups;
    if (curr) {
      expandedGroups = expandedGroups.filter(item => item !== groupVal);
    } else {
      if (expandedGroups.indexOf(groupVal) === -1) {
        expandedGroups = expandedGroups.concat([groupVal]);
      }
    }
    this.setState(prevState => ({
      ...prevState,
      expandedGroups
    }));
  };

  renderTestTable = () => {
    let result;
    const { classes } = this.props;
    if (this.props.testingRateTables) {
      const columns = [
        { id: 'scenario', label: 'Scenario'},
        { id: 'loan_purpose', label: 'Loan Purpose'},
        { id: 'loan_amount', label: 'Loan Amount'},
        { id: 'loan_type', label: 'Loan Type'},
        { id: 'loan_term', label: 'Loan Term'},
        { id: 'ltv', label: 'LTV'},
        { id: 'property_type', label: 'Property Type'},
        { id: 'property_usage', label: 'Property Usage'},
        { id: 'credit_score', label: 'Credit Score'},
        { id: 'cash_out', label: 'Cash Out'},
        { id: 'zipcode', label: 'Zipcode'},
        { id: 'lockDays', label: 'Day lock'},
        { id: 'result', label: 'Result'},
      ];
      const dataRows = this.props.testingRateTables;
      const groupedData = (dataRows && dataRows.length) ? this.getGroupedData(dataRows) : {};
      const totalRows = this.props.testingRateTables.length;
      const isSelected = (index) => this.state.selected.indexOf(index) !== -1;
      result = (
        <TableContainer component={Paper} square={true} elevation={0} className="testing-rate-container">
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={this.state.numSelected > 0 && this.state.numSelected < totalRows}
                    checked={totalRows > 0 && this.state.numSelected === totalRows}
                    onChange={this.handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all test cases' }}
                    color="primary"
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell style={{ minWidth: 170 }}>
                  {this.enhancedTableToolbar()}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData).map((key, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        colSpan={columns.length + 2}
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={() => this.expandRow(key)}
                      >
                        <IconButton>
                          <Icon>
                            {this.groups[key] ? 'expand_more' : 'chevron_right'}
                          </Icon>
                        </IconButton>
                        <span>{key}</span>
                      </TableCell>
                    </TableRow>
                    {this.groups[key] && groupedData[key].map((item, index) => {
                      const isItemSelected = isSelected(index);
                      const params = item.params;
                      const results = item.results;
                      const scenario = item.scenario;
                      let viewResult;
                      if(results && Object.keys(results).length) {
                        viewResult = (
                          <IconButton 
                            type="button" 
                            color="primary" 
                            aria-label="View result"
                            className={classes.iconButton}
                            onClick={() => this.onClickOpenDialog('details', results)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        );
                      }
                      const runButtonText = (results) ? 'Rerun' : 'Run';
                      return (
                        <TableRow key={index} selected={isItemSelected}>
                          <TableCell padding="checkbox" component="th" scope="row">
                            <Checkbox
                              disabled
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': index }}
                            />
                          </TableCell>
                          <TableCell>{scenario}</TableCell>
                          <TableCell>{params.loanPurpose}</TableCell>
                          <TableCell>{params.loanAmount}</TableCell>
                          <TableCell>{params.loanType}</TableCell>
                          <TableCell>{params.loanTerm}</TableCell>
                          <TableCell>{params.ltv}</TableCell>
                          <TableCell>{params.propertyType}</TableCell>
                          <TableCell>{params.propertyUsage}</TableCell>
                          <TableCell>{params.creditScore}</TableCell>
                          <TableCell>{params.cashOut}</TableCell>
                          <TableCell>{params.zipCode}</TableCell>
                          <TableCell>{params.lockDays}</TableCell>
                          <TableCell>{viewResult}</TableCell>
                          <TableCell>
                            <Button 
                              variant="contained" 
                              className={classes.runButton} 
                              size="small" 
                              color="primary"
                              onClick={() => this.onClickRunTest(item.testCaseId)}
                            >
                              {runButtonText}
                            </Button>
                            <IconButton type="button" color="primary" className="btn-action" aria-label="Edit" onClick={() => this.onClickEdit(item.testCaseId)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton type="button" color="primary" className="btn-action btn-delete" aria-label="Delete" onClick={() => this.onClickDelete(item._id, item.testCaseId)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return result;
  }
  
  render() {
    const { classes } = this.props;
    let dialogContent;
    switch (this.state.dialogContentType) {
      case 'edit':
        dialogContent = (
          <TestingRateForm 
            testCaseId={this.state.testCaseId} 
            onClose={this.handleCloseDialog} 
            parentState={this.props.parentState}
            parentProps={this.props.parentProps}
          />
        );
        break;
      default:
        dialogContent = (
          <TestingRateDetails details={this.state.resultDetails} />
        );
        break;
    }
    return (
      <div>
        {this.renderTestTable()}
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogContent className={classes.dialogContent}>
            {dialogContent}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    rateTestingResults: state.testing.rateTestingResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runTestingRate: (params) => dispatch(runTestingRate(params)),
    deleteRateTestCase: (testSuite, testCase, ownProps) => dispatch(deleteRateTestCase(testSuite, testCase, ownProps)),
  };
};

TestingRateTable.propTypes = {
  classes: PropTypes.object,
  runTestingRate: PropTypes.func,
  deleteRateTestCase: PropTypes.func,
  rateTestingResults: PropTypes.object,
  selectedLenders: PropTypes.array,
  testingRateTables: PropTypes.array,
  parentProps: PropTypes.any,
  parentState: PropTypes.any,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(TestingRateTable));