import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorIcon from '@material-ui/icons/Error';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DropzoneDialog } from 'material-ui-dropzone';

import { getRateFilesOfLender, parseRateFile, saveParsedRateFile, uploadRateFileOfLender, uploadMetadataFileOfLender, downloadFile, deleteFile } from './../../../actions';
import { dateTimeFormat } from '../../../utils';
import ParseDetails from '../Lender/ParseDetails';

const style = {
  parseButton: {
    color: '#FFFFFF',
    boxShadow: 'none',
    margin: '0 2px',
  }
};

class FileTableContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openUpload: false,
      openDialog: false,
      dialogType: 'details',
      lenderId: this.props.match.params.id,
      parsedIndex: null,
      parsedId: '',
      message: '',
      selectedFile: null,
      metadataFile: {
        openUpload: false,
        fileName: '',
        selectedFile: null,
      }
    };
    this.onClickOpenUpload = this.onClickOpenUpload.bind(this);
    this.onClickUploadFile = this.onClickUploadFile.bind(this);
    this.handleCloseUpload = this.handleCloseUpload.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.onClickOpenDialog = this.onClickOpenDialog.bind(this);
    this.onClickParseFile = this.onClickParseFile.bind(this);
    this.onClickSaveParsedRateFile = this.onClickSaveParsedRateFile.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.onClickOpenUploadMetadataFile = this.onClickOpenUploadMetadataFile.bind(this);
    this.handleCloseUploadMetadataFile = this.handleCloseUploadMetadataFile.bind(this);
    this.onClickUploadMetadataFile = this.onClickUploadMetadataFile.bind(this);
  }

  componentDidMount() {
    this.props.getRateFilesOfLender(this.state.lenderId);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.rateFileResult !== this.props.rateFileResult) {
      const rateFileResult = this.props.rateFileResult;
      if(rateFileResult && typeof(rateFileResult) === 'object' && Object.keys(rateFileResult).length) {
        const parsedId = rateFileResult.id;
        this.props.rateFiles[this.state.parsedIndex].parsedId = parsedId;
        this.props.rateFiles[this.state.parsedIndex].message = null;
        this.forceUpdate();
      }
    }
    if(prevProps.rateFileMessage !== this.props.rateFileMessage) {
      const message = this.props.rateFileMessage;
      this.props.rateFiles[this.state.parsedIndex].message = message;
      this.props.rateFiles[this.state.parsedIndex].parsedId = null;
      this.forceUpdate();
    }
  }

  onClickOpenUpload = () => {
    this.setState({
      openUpload: true,
    });
  }

  onClickOpenUploadMetadataFile = (fileName) => {
    fileName = fileName.split('.').slice(0, -1).join('.');
    this.setState(prevState => ({
      ...prevState,
      metadataFile: {
        openUpload: true,
        fileName,
      }
    }));
  }

  handleCloseUploadMetadataFile = () => {
    this.setState(prevState => ({
      ...prevState,
      metadataFile: {
        openUpload: false,
        fileName: '',
      }
    }));
  }

  onClickOpenDialog = (content, dialogType) => {
    this.setState(prevState => ({
      ...prevState,
      openDialog: true,
      dialogType,
      parsedId: (content.parsedId) || '',
      message: (content.message) || '',
    }));
  }

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  }

  handleCloseUpload = () => {
    this.setState({
      openUpload: false
    });
  }

  onClickUploadFile = (files) => {
    this.setState(prevState => ({
      ...prevState,
      selectedFile: files[0],
      openUpload: false
    }), () => this.handleUploadFile());
  }

  onClickUploadMetadataFile = (files) => {
    this.setState(prevState => ({
      ...prevState,
      metadataFile: {
        ...prevState.metadataFile,
        openUpload: false,
        selectedFile: files[0],
      }
    }), () => this.handleUploadMetadataFile());
  }

  handleUploadFile = () => {
    const data = new FormData() ;
    const { selectedFile, lenderId } = this.state;
    data.append('file', selectedFile);
    this.props.uploadRateFileOfLender(lenderId, data, this.props);
  }

  handleUploadMetadataFile = () => {
    const data = new FormData() ;
    const { lenderId } = this.state;
    const { selectedFile, fileName } = this.state.metadataFile;
    data.append(`${fileName}.json`, selectedFile);
    this.props.uploadMetadataFileOfLender(lenderId, data, this.props);
  }

  onClickParseFile = (filename, parsedIndex) => {
    this.setState(prevState => ({
      ...prevState,
      parsedIndex
    }));
    this.props.parseRateFile(filename, this.state.lenderId);
  }

  onClickDownloadFile = (fileKey, fileName, type) => {
    this.props.downloadFile(fileKey, fileName, type);
  }

  onClickDeleteFile = (fileKey) => {
    this.props.deleteFile(this.state.lenderId, fileKey, this.props);
  }
  
  onClickSaveParsedRateFile = () => {
    this.props.saveParsedRateFile(this.state.parsedId);
    this.handleCloseDialog();
  }

  renderToolBar = () => {
    let result;
    result = (
      <Grid container spacing={0}>
        <Grid item md={10}></Grid>
        <Grid item md={2} className={'text-right'}>
          <Button 
            variant="contained" 
            size="small" 
            color="primary"
            className="btn-upload"
            startIcon={<CloudUploadIcon />}
            onClick={() => this.onClickOpenUpload()}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    );
    return result;
  }

  renderFileTable = () => {
    let result;
    if (this.props.rateFiles && this.props.rateFiles.length) {
      const { classes } = this.props;
      const rateFileRows = this.props.rateFiles;
      const columns = [
        { id: 'state_name', label: 'Name'},
        { id: 'last_modified', label: 'Last modified'},
        { id: 'size', label: 'Size'},
        { id: 'metadata', label: 'Metadata', align: 'right'},
        { id: 'view', label: 'View', align: 'center'},
        { id: 'actions', label: 'Actions', align: 'center'},
      ];
      result = (
        <TableContainer component={Paper} square={true} elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rateFileRows.map((row, index) => {
                let viewDetails;
                let errorMessage;
                if(row && row.parsedId) {
                  viewDetails = (
                    <IconButton 
                      type="button" 
                      color="primary" 
                      className="btn-action" 
                      aria-label="View details" 
                      onClick={() => this.onClickOpenDialog({parsedId: row.parsedId}, 'details')}>
                      <VisibilityIcon />
                    </IconButton>
                  );
                }
                if(row && row.message) {
                  errorMessage = (
                    <IconButton 
                      type="button" 
                      color="primary" 
                      className="btn-error" 
                      aria-label="Error"
                      onClick={() => this.onClickOpenDialog({message: row.message}, 'error')}>
                      <ErrorIcon />
                    </IconButton>
                  );
                }
                const { metadata } = row;
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell>{dateTimeFormat(new Date(row.lastModified))}</TableCell>
                    <TableCell>{row.size}</TableCell>
                    <TableCell align="right">
                      { metadata ? <IconButton 
                        type="button" 
                        color="primary" 
                        className="btn-action" 
                        aria-label="Download" 
                        onClick={() => this.onClickDownloadFile(metadata.path, metadata.name, 'metadata')}
                      >
                        <GetAppIcon />
                      </IconButton> : null }
                      <IconButton 
                        type="button" 
                        color="primary" 
                        className="btn-action" 
                        aria-label="Upload" 
                        onClick={() => this.onClickOpenUploadMetadataFile(row.name)}
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">{viewDetails}{errorMessage}</TableCell>
                    <TableCell align="center">
                      <Button 
                        variant="contained" 
                        className={classes.parseButton} 
                        size="small" 
                        color="primary"
                        onClick={() => this.onClickParseFile(row.path, index)}
                      >
                        Parse
                      </Button>
                      <IconButton 
                        type="button" 
                        color="primary" 
                        className="btn-action" 
                        aria-label="Download" 
                        onClick={() => this.onClickDownloadFile(row.path, row.name, 'rate')}
                      >
                        <GetAppIcon />
                      </IconButton>
                      <IconButton 
                        type="button" 
                        color="primary" 
                        className="btn-action btn-delete" 
                        aria-label="Delete"
                        onClick={() => this.onClickDeleteFile(row.path)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return result;
  }

  render() {
    let dialogContent;
    let dialogActions;
    let dialogWidth;
    switch (this.state.dialogType) {
      case 'error':
        dialogWidth = 'md';
        dialogContent = <span style={{ whiteSpace: 'pre-line' }}>{this.state.message}</span>;
        dialogActions = null;
        break;
      default:
        dialogWidth = 'xl';
        dialogContent = <ParseDetails parsedId={this.state.parsedId} />;
        dialogActions = <DialogActions>
          <Button 
            variant="outlined" 
            color="primary" 
            size="small" 
            className="mr-2"
            onClick={this.handleCloseDialog}>
          Cancel
          </Button>
          <Button 
            variant="contained" 
            size="small" 
            color="primary"
            className="btn-save-parsed"
            onClick={this.onClickSaveParsedRateFile}>
          Save
          </Button>
        </DialogActions>;
        break;
    }
    return (
      <div>
        {this.renderToolBar()}
        {this.renderFileTable()}
        <DropzoneDialog
          open={this.state.metadataFile.openUpload}
          onSave={this.onClickUploadMetadataFile}
          acceptedFiles={['application/json']}
          showPreviews={true}
          maxFileSize={5000000}
          filesLimit={1}
          fullWidth={true}
          maxWidth="md"
          onClose={this.handleCloseUploadMetadataFile}
        />
        <DropzoneDialog
          open={this.state.openUpload}
          onSave={this.onClickUploadFile}
          acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
          showPreviews={true}
          maxFileSize={5000000}
          filesLimit={1}
          fullWidth={true}
          maxWidth="md"
          onClose={this.handleCloseUpload}
        />
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          fullWidth={true}
          maxWidth={dialogWidth}
        >
          <DialogContent>
            {dialogContent}
          </DialogContent>
          {dialogActions}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    rateFiles: state.lender.rateFiles,
    rateFileResult: state.parsedRate.parseRateFileResult,
    rateFileMessage: state.parsedRate.parseRateFileMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateFilesOfLender: (lenderId) => dispatch(getRateFilesOfLender(lenderId)),
    parseRateFile: (filename, lender) => dispatch(parseRateFile(filename, lender)),
    saveParsedRateFile: (parsedRateId) => dispatch(saveParsedRateFile(parsedRateId)),
    uploadRateFileOfLender: (lenderId, file, ownProps) => dispatch(uploadRateFileOfLender(lenderId, file, ownProps)),
    uploadMetadataFileOfLender: (lenderId, file, ownProps) => dispatch(uploadMetadataFileOfLender(lenderId, file, ownProps)),
    downloadFile: (fileKey, fileName, type) => dispatch(downloadFile(fileKey, fileName, type)),
    deleteFile: (lenderId, fileKey, ownProps) => dispatch(deleteFile(lenderId, fileKey, ownProps)),
  };
};

FileTableContainer.propTypes = {
  classes: PropTypes.object,
  rateFiles: PropTypes.array,
  getRateFilesOfLender: PropTypes.func,
  parseRateFile: PropTypes.func,
  saveParsedRateFile: PropTypes.func,
  uploadRateFileOfLender: PropTypes.func,
  uploadMetadataFileOfLender: PropTypes.func,
  rateFileResult: PropTypes.object,
  rateFileMessage: PropTypes.string,
  match: PropTypes.any,
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(FileTableContainer));
