import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Form, Button, Row, Col } from 'react-bootstrap';

class ConditionAddItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      params: [],
      operators: [],
      values: [],
      condition: {
        param: '',
        operator: '',
        value: '',
      },
    };
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onChangeParam = this.onChangeParam.bind(this);
    this.onChangeOperator = this.onChangeOperator.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.getOperator = this.getOperator.bind(this);
  }

  componentDidMount() {
    const conditions = this.props.conditions;
    const params = conditions.filter(condition => condition.nameTag !== 'Operator').map(condition => {
      return { name: condition.name, nameTag: condition.nameTag };
    });
    if(params && params.length) {
      const defaultParam = params[0].nameTag;
      const values = this.getValuesByParam(defaultParam);
      this.setState(prevState => ({
        params,
        values,
        condition: {
          ...prevState.condition,
          param: defaultParam,
          value: (values && values.length) ? values[0].value : '',
        },
      }), () => this.getOperator());
    }
  }

  getValuesByParam(selectedParam) {
    const conditions = this.props.conditions;
    const selectedCondition = conditions.find(codition => codition.nameTag === selectedParam);
    if (selectedCondition && Object.keys(selectedCondition).length) {
      const values =  (selectedCondition.values) ? selectedCondition.values : [];
      return values;
    }
    return [];
  }

  getOperator() {
    let operators;
    if(this.state.values && this.state.values.length) {
      operators = [
        {
          'value' : 'EQ',
          'name' : '='
        }
      ];
    } else {
      const conditions = this.props.conditions;
      operators = conditions.filter(condition => condition.nameTag === 'Operator')[0].values;
    }
    this.setState(prevState => ({
      operators,
      condition: {
        ...prevState.condition,
        operator: operators[0].value,
      },
    }));
    return operators;
  }

  onChangeParam(event) {
    const selectedParam = event.target.value;
    const values = this.getValuesByParam(selectedParam);
    this.setState(prevState => ({
      values,
      condition: {
        ...prevState.condition,
        param: selectedParam,
        value: (values && values.length) ? values[0].value : '',
      },
    }), () => this.getOperator());
  }

  renderParamSelectBox() {
    let result;
    result = (
      <Form.Group controlId="formParam">
        <Form.Label>Param</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          value={this.state.condition.param}
          onChange={this.onChangeParam}
        >
          {(this.state.params.length) ? this.state.params.map((param, index) => (
            <option key={index} value={param.nameTag}>{param.name}</option>
          )) : <option></option>}
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  renderOperator() {
    const result = (
      <Form.Group controlId="formOperator">
        <Form.Label>Operator</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          onChange={this.onChangeOperator}
        >
          {(this.state.operators.length) ? this.state.operators.map((operator, index) => (
            <option key={index} value={operator.value}>{operator.name}</option>
          )) : <option></option>}
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  onChangeOperator(event) {
    const selectedOperator = event.target.value;
    this.setState(prevState => ({
      condition: {
        ...prevState.condition,
        operator: selectedOperator
      },
    }));
  }

  renderValue() {
    let control;
    if(this.state.values && this.state.values.length) {
      control = (
        <Form.Control 
          size="sm" 
          as="select"
          onChange={this.onChangeValue}
        >
          {this.state.values.map((value, index) => (
            <option key={index} value={value.value}>{value.name}</option>
          ))}
        </Form.Control>
      );
    } else {
      control = (
        <Form.Control size="sm" type="text" onChange={this.onChangeValue}/>
      );
    }
    const result = (
      <Form.Group controlId="formValue">
        <Form.Label>Value</Form.Label>
        {control}
      </Form.Group>
    );
    return result;
  }

  onChangeValue(event) {
    const selectedValue = event.target.value;
    this.setState(prevState => ({
      condition: {
        ...prevState.condition,
        value: selectedValue
      },
    }));
  }

  handleSubmitForm(event) {
    event.preventDefault();
    this.props.onConditionFetched(this.state.condition);
    this.props.onClose();
  }

  renderAddItemForm() {
    let result;
    result = (
      <Card elevation={0}>
        <CardHeader
          title={
            <h5 className="title">Add new condition</h5>
          }
        />
        <CardContent>
          <Form onSubmit={this.handleSubmitForm}>
            <Row>
              <Col>
                {this.renderParamSelectBox()}
              </Col>
              <Col>
                {this.renderOperator()}
              </Col>
              <Col>
                {this.renderValue()}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button 
                  color="primary" 
                  size="sm" 
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </CardContent>
      </Card>
    );
    return result;
  }

  render() {
    return this.renderAddItemForm();
  }
}


ConditionAddItem.propTypes = {
  conditions: PropTypes.any, 
  onConditionFetched: PropTypes.func,
  onClose: PropTypes.func
};

export default ConditionAddItem;
