import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import './Admin.scss';
import Header from './../Header';
import AdminSidebar from './../../Sidebar/Admin';
import AlertBox from './../../Shared/Alert';
import Spinner from './../../Shared/Spinner';
class AdminLayout extends React.Component {
  render() {
    return (
      <div className="admin-layout">
        <Header />
        <Grid container spacing={0} className="admin-container">
          <Grid item md={2}>
            <AdminSidebar />
          </Grid>
          <Grid item md={10} >
            <div className="main-wrapper">
              <AlertBox/>
              {this.props.children}
            </div>
          </Grid>
        </Grid>
        {this.props.loading ? <Spinner/> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loading,
  };
};

AdminLayout.propTypes = {
  loading: PropTypes.bool, 
  children: PropTypes.any, 
};

export default withRouter(connect(mapStateToProps, null)(AdminLayout));
