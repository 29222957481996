import {
  GET_ZIPSTATECODES,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { ZipStateCodeService } from './../services';

export const getZipStateCodes = (pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    ZipStateCodeService.getZipStateCodes(pageNumber, pageSize).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_ZIPSTATECODES,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};