import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SpanValue from './SpanValue';
class MortgageResultAdjustment extends React.Component {

  renderAdjustmentTable() {
    let result;
    const lenderName = this.props.details.lenderName;
    const program = this.props.details.program;
    const loanTerm = this.props.details.loanTerm;
    const lockDays = this.props.details.lockDays;
    const ltv = this.props.details.ltv;
    const adjustments = this.props.details.adjustments;

    const baseRateValue = this.props.details.baseRate;
    const basePriceValue = this.props.details.basePrice;
    const closingCostValue = this.props.details.closingCost;

    result = (
      <div className="adjustment-details-wrapper">
        <h5>{lenderName}</h5>
        <label>{program}</label><br></br>
        <label>{lockDays}, {loanTerm}</label>
        <Table className="adjustment-table" responsive>
          <thead>
            <tr>
              <th>Adjustments - LTV: {ltv}</th>
              <th className="text-right">Rate</th>
              <th className="text-right">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr className="base-price">
              <td>Base Price</td>
              <td className="text-right">
                <SpanValue value={baseRateValue} format="percent" />
              </td>
              <td className="text-right">
                <SpanValue value={basePriceValue} format="currency" />
              </td>
            </tr>
            {adjustments.map((adjustment, adjustmentIndex) => {
              const name = adjustment.name;
              const amountValue = adjustment.amount;
              const value = adjustment.value;
              const valueType = adjustment.valueType;
              let valueText = '';
              if(valueType === 'Percentage') {
                valueText = (<SpanValue value={value} format="percent" />);
              }
              return (
                <tr key={adjustmentIndex}>
                  <td>{name}</td>
                  <td className="text-right">
                    {valueText}
                  </td>
                  <td className="text-right">
                    <SpanValue value={amountValue} format="currency" />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="closing-cost">
              <td>Closing Cost</td>
              <td className="text-right"></td>
              <td className="text-right">
                <SpanValue value={closingCostValue} format="currency" />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    );
    return result;
  }

  render() {
    return this.renderAdjustmentTable();
  }
}

MortgageResultAdjustment.propTypes = {
  details: PropTypes.object,
};

export default MortgageResultAdjustment;