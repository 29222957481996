import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SignUpForm extends React.Component {

  renderSignUpForm() {
    let result;
    result = (
      <Form>
        <Form.Row>
          <Col>
            <Form.Group controlId="formSignUpFirstName">
              <Form.Control type="text" placeholder="First Name" size="sm" />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formSignUpLastName">
              <Form.Control type="text" placeholder="Last Name" size="sm" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Group controlId="formSignUpEmail">
          <Form.Control type="email" placeholder="Email" size="sm" />
        </Form.Group>

        <Form.Group controlId="formSignUpPassword">
          <Form.Control type="password" placeholder="Password" size="sm" />
        </Form.Group>

        <Form.Group controlId="formSignUpSubmit">
          <Button variant="primary" type="submit" size="sm" block>
            Create account
          </Button>
        </Form.Group>

        <Form.Group controlId="backToSignIn" className="text-center">
          <Link to="/portal/signin">Back to Sign in</Link>
        </Form.Group>
      </Form>
    );
    return result;
  }

  render() {
    return this.renderSignUpForm();
  }
}

export default SignUpForm;
