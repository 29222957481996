import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const FileService = {
  getRateFilesOfLender,
  uploadRateFileOfLender,
  uploadMetadataFileOfLender,
  downloadFile,
  deleteFile,
};

function getRateFilesOfLender(lenderId) {
  return apiService.get(`v1/file/${lenderId}`, { headers: authHeader() });
}

function uploadRateFileOfLender(lenderId, file) {
  return apiService.post(`v1/file/${lenderId}/rate`, file, { headers: authHeader() });
}

function uploadMetadataFileOfLender(lenderId, file) {
  return apiService.post(`v1/file/${lenderId}/metadata`, file, { headers: authHeader() });
}

function downloadFile(fileKey, type) {
  return apiService.get(`v1/file/?fileKey=${fileKey}&type=${type}`, { headers: authHeader(), responseType: 'blob' });
}

function deleteFile(fileKey) {
  return apiService.delete(`v1/file/?fileKey=${fileKey}`, { headers: authHeader() });
}