import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { getParsedRateTables } from './../../../actions';
import RateTable from '../../../components/Tables/RateTable';

const style = ({
  iconButton: {
    padding: 0,
  },
});

class ParseRateTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parsedId: this.props.parsedId,
      page: 0,
      rowsPerPage: 50,
      searchingKey: '',
      loanTypeId: '',
      orderBy: 'name',
      order: 'asc',
    };
    this.handleTableFetched = this.handleTableFetched.bind(this);
  }

  componentDidMount() {
    this.props.getParsedRateTables(this.state.parsedId, this.state.loanTypeId, this.state.searchingKey, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }

  handleTableFetched(fetchedState) {
    this.setState({
      page: fetchedState.page,
      rowsPerPage: fetchedState.rowsPerPage,
      orderBy: fetchedState.orderBy,
      order: fetchedState.order,
    });
    this.props.getParsedRateTables(this.state.parsedId, this.state.loanTypeId, this.state.searchingKey, fetchedState.page + 1, fetchedState.rowsPerPage, fetchedState.orderBy, fetchedState.order);
  }

  renderRateTabe() {
    let result;
    if (this.props.rateTables && this.props.rateTables.totalDocs >= 0) {
      result = (
        <RateTable 
          rateTables={this.props.rateTables}
          onTableFetched={this.handleTableFetched}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          orderBy={this.state.orderBy}
          order={this.state.order}
          dialogSize="md"
        />
      );
    }
    return result;
  }
  
  render() {
    return (
      <div>{this.renderRateTabe()}</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    rateTables: state.parsedRate.parsedRateTables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getParsedRateTables: (
      parsedId,
      loanTypeId,
      searchingKey,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ) => dispatch(getParsedRateTables(
      parsedId,
      loanTypeId,
      searchingKey,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ))
  };
};

ParseRateTableContainer.propTypes = {
  classes: PropTypes.object,
  rateTables: PropTypes.any,
  parsedId: PropTypes.string,
  getParsedRateTables: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ParseRateTableContainer));
