import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const LenderService = {
  getAllLender,
  getAllLenderPublished,
  updatePublishable,
  getLenderListing,
  getRateTablesOfLender,
  getLenderInfo,
  getAdjustmentTablesOfLender,
};

function getAllLender() {
  return apiService.get('v1/lender', { headers: authHeader() });
}

function getAllLenderPublished() {
  return apiService.get('v1/lender/published', { headers: authHeader() });
}

function updatePublishable(lenderId, isPublishable) {
  return apiService.put(`v1/lender/${lenderId}/publishable`, { isPublishable }, { headers: authHeader() });
}

function getLenderListing(searchingKey, pageNumber, pageSize, type) {
  return apiService.get(
    `v1/lender?searchingKey=${searchingKey}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { headers: authHeader() }
  );
}

function getLenderInfo(lenderId) {
  return apiService.get(
    `v1/lender/${lenderId}/info`,
    { headers: authHeader() }
  );
}

function getRateTablesOfLender(lenderId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order) {
  return apiService.get(
    `v1/lender/${lenderId}/rateTables?searchingKey=${searchingKey}&loanTypeId=${loanTypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}`, 
    { headers: authHeader() }
  );
}

function getAdjustmentTablesOfLender(lenderId, searchingKey, type, pageNumber, pageSize, orderBy, order) {
  return apiService.get(
    `v1/lender/${lenderId}/adjustmentTables?searchingKey=${searchingKey}&type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}`,
    { headers: authHeader() }
  );
}
