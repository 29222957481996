import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { percentFormat, currencyFormat } from './../../utils';
import { Table } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MortgageResultAdjustment from './Adjustment';
import SpanValue from './SpanValue';

const style = {
  dialogContent: {
    padding: 10,
  },
  expandRow: {
    padding: 0,
    marginLeft: 5,
  }
};

class MortgageResultTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRates: [],
      openAdjustmentDetails: false,
      adjustmentDetails: {
        closingCost: 0,
        ltv: this.props.ltv,
        lenderName: '',
        program: '',
        loanTerm: this.props.loanTerm,
        basePrice: 0,
        lockDays: this.props.lockDays,
        adjustments: []
      }
    };
    this.handleAdjustmentDetails = this.handleAdjustmentDetails.bind(this);
    this.expandRow = this.expandRow.bind(this);
  }

  onClickOpenAdjustmentDetails = ({closingCost, lenderName, program, baseRate, basePrice, adjustments}) => {
    this.setState(prevState => ({
      openAdjustmentDetails: true,
      adjustmentDetails: {
        ...prevState.adjustmentDetails,
        closingCost,
        ltv: this.props.ltv,
        lenderName,
        program,
        loanTerm: this.props.loanTerm,
        baseRate,
        basePrice,
        lockDays: this.props.lockDays,
        adjustments
      }
    }));
  }

  handleAdjustmentDetails = () => {
    this.setState({
      openAdjustmentDetails: false,
    });
  }

  getGroupedData = rows => {
    const groupedData = rows.reduce((acc, item) => {
      let key = `${this.props.loanTerm}-${item['value']}`;
      let groupData = acc[key] || {};
      acc[key] = {...groupData, ...item};
      return acc;
    }, {});
    const expandedGroups = {};
    Object.keys(groupedData).forEach(item => {
      expandedGroups[item] = this.state.expandedRates.indexOf(item) !== -1;
    });
    this.expandedRates = expandedGroups;
    return groupedData;
  };

  expandRow = (expandKey) => {
    const curr = this.expandedRates[expandKey];
    let expandedRates = this.state.expandedRates;
    if (curr) {
      expandedRates = expandedRates.filter(item => item !== expandKey);
    } else {
      if (expandedRates.indexOf(expandKey) === -1) {
        expandedRates = expandedRates.concat([expandKey]);
      }
    }
    this.setState(prevState => ({
      ...prevState,
      expandedRates
    }), () => this.forceUpdate());
  }

  renderRateResultTable = () => {
    const { classes } = this.props;
    const rates = this.props.rates;
    const tableData = (rates && rates.length) ? this.getGroupedData(rates) : [];
    const loanTerm = this.props.loanTerm;
    const tableRows = (Object.keys(tableData).length) ? Object.keys(tableData).map(key => {
      const rateItem = tableData[key];
      const rate = (rateItem.value) ? percentFormat(rateItem.value) : 'NULL';
      const quotes = rateItem.quotes;
      const quoteData = this.expandedRates[key] ? quotes : [rateItem.quotes[0]];
      let rowSpan = 1;
      let rowScope = 'row';
      let rows = '';
      if(quotes && quotes.length) {
        rowSpan = (this.expandedRates[key]) ? quotes.length : rowSpan;
        rowScope = 'rowgroup';
        rows = quoteData.map((quote, quoteIndex) => {
          const expandKey = `${loanTerm}-${rateItem.value}`;
          const lenderName = quote.lender;
          const program = quote.program;
          const apr = (quote.apr) ? percentFormat(quote.apr) : 'NULL';
          const monthlyPayment = (quote.monthlyPayment) ? currencyFormat(quote.monthlyPayment) : 'NULL';
          const baseRate = quote.baseRate;
          const basePrice = quote.basePrice;
          const adjustments = quote.adjustments;
          const closingCostValue = quote.closingCost;
          const closingCost= (
            <span
              className="closing-cost" 
              onClick={() => this.onClickOpenAdjustmentDetails(
                {closingCost: closingCostValue, lenderName, program, baseRate, basePrice, adjustments}
              )}>
              <SpanValue value={closingCostValue} format="currency"/>
            </span>
          );
          const expandIcon = (quotes.length > 1) ?(
            <IconButton className={classes.expandRow} onClick={() => this.expandRow(expandKey)}>
              <Icon>
                {(this.expandedRates && this.expandedRates[expandKey]) ? 'expand_more' : 'expand_less'}
              </Icon>
            </IconButton>
          ) : null;
          const rateCell = (quotes.length === 1 || (quotes.length > 1 && quoteIndex === 0)) 
            ? (<th rowSpan={rowSpan} scope={rowScope}>{rate}{expandIcon}</th>)
            : null;
          const lenderNameCell = (quotes.length > 1 && quoteIndex > 0)
            ? (<th scope={rowScope}>{lenderName}</th>)
            : (<th>{lenderName}</th>);
          const tableRow = (
            <tr key={quoteIndex}>
              {rateCell}
              {lenderNameCell}
              <td>{program}</td>
              <td>{apr}</td>
              <td>{closingCost}</td>
              <td>{monthlyPayment}</td>
            </tr>
          );
          return tableRow;
        });
      }
      return rows;
    }) : null;
    const result = (
      <Table className="mortgage-table" responsive>
        <thead>
          <tr>
            <th>Interest Rate</th>
            <th>Lender Name</th>
            <th>Program</th>
            <th>APR</th>
            <th>Closing Cost</th>
            <th>Monthly Payment</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
    );
    return result;
  }

  render() {
    const { classes } = this.props;
    const adjustmentDetailSize = (this.props.adjustmentDetailSize) ? this.props.adjustmentDetailSize : 'md';
    return (
      <Fragment>
        {this.renderRateResultTable()}
        <Dialog
          open={this.state.openAdjustmentDetails}
          onClose={this.handleAdjustmentDetails}
          fullWidth={true}
          maxWidth={adjustmentDetailSize}
        >
          <DialogContent className={classes.dialogContent}>
            <MortgageResultAdjustment details={this.state.adjustmentDetails} />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

MortgageResultTable.propTypes = {
  rates: PropTypes.array,
  loanTerm: PropTypes.string,
  lockDays: PropTypes.string,
  ltv: PropTypes.number,
  adjustmentDetailSize: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles(style)(MortgageResultTable);
