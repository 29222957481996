import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Form, Button, Row, Col } from 'react-bootstrap';
import ConditionInput from '../../../components/Condition/Input';
import LoanTypeWhitoutAllSelectBox from '../../../components/Controls/SelectBoxLoanTypeWhithoutAll';
import ProgramTypeSelectBox from '../../../components/Controls/SelectBoxProgramType';
import AdjustmentTableCreateRule from './Rule/CreateRule';
import Spinner from '../../../components/Shared/Spinner';
import { getAdjustmentDetails, updateCustomAdjustment, createCustomAdjustment } from '../../../actions';
import './Style.scss';

const style = ({
  addRuleBtn: {
    cursor: 'pointer',
  },
});

class AdjustmentTableForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      dataForm: {
        loanType: null,
        lender: this.props.match.params.id,
        programType: 'Conforming',
        program: {
          name: '',
          lookupParam: 'LTV',
          condition: {},
          rules: [],
        }
      },
    };
    this.getDefaultLoanType = this.getDefaultLoanType.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onClickRenderCreateRule = this.onClickRenderCreateRule.bind(this);
    this.onChangeAdjustmentName = this.onChangeAdjustmentName.bind(this);
    this.onChangeAdjustmentLookupParam = this.onChangeAdjustmentLookupParam.bind(this);
    this.onChangeLoanType = this.onChangeLoanType.bind(this);
    this.onChangeProgramType = this.onChangeProgramType.bind(this);
    this.handleAdjustmentConditionChange = this.handleAdjustmentConditionChange.bind(this);
    this.handleAdjustmentRuleChange = this.handleAdjustmentRuleChange.bind(this);
    this.onClickDeleteRule = this.onClickDeleteRule.bind(this);
  }

  componentDidMount() {
    const { adjustmentId, objectType } = this.props;
    if (adjustmentId) {
      this.props.getAdjustmentDetails(adjustmentId, objectType);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.adjustmentDetails !== this.props.adjustmentDetails) {
      const adjustmentDetails = this.props.adjustmentDetails;
      if(adjustmentDetails && Object.keys(adjustmentDetails).length) {
        this.setState(prevState => ({
          ...prevState,
          isEdit: true,
          dataForm: {
            ...prevState.dataForm,
            program: adjustmentDetails
          }
        }));
      }
    }
  }
  
  onClickRenderCreateRule = () => {
    const rule = {
      name: '',
      valueType: 'Percentage',
      condition: {},
      values: [],
    };
    this.setState(prevState => {
      return ({
        ...prevState,
        dataForm: {
          ...prevState.dataForm,
          program: {
            ...prevState.dataForm.program,
            rules: [
              ...prevState.dataForm.program.rules,
              rule
            ]
          }
        }
      });
    });
  }

  getDefaultLoanType = (loanType) => {
    const { loanType: formLoanType } = this.state.dataForm;
    if (formLoanType === null || formLoanType === 'null') {
      this.setState(prevState => ({
        ...prevState,
        dataForm: {
          ...prevState.dataForm,
          loanType
        }
      }));
    }
  }

  onChangeAdjustmentName = (event) => {
    const name = event.target.value;
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        program: {
          ...prevState.dataForm.program,
          name
        },
      }
    }));
  }

  onChangeAdjustmentLookupParam = (event) => {
    const lookupParam = event.target.value;
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        program: {
          ...prevState.dataForm.program,
          lookupParam
        },
      }
    }));
  }

  handleAdjustmentConditionChange = (condition) => {
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        program: {
          ...prevState.dataForm.program,
          condition
        },
      }
    }));
  }

  onChangeLoanType = (loanType) => {
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        loanType
      }
    }));
  }
  
  onChangeProgramType = (event) => {
    const programType = event.target.value;
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        programType
      }
    }));
  }

  handleAdjustmentRuleChange = (adjustmentRule, index) => {
    const rules = this.state.dataForm.program.rules;
    if(rules && rules[index]) {
      rules[index] = adjustmentRule;
    } else {
      rules.push(adjustmentRule);
    }
    this.setState(prevState => ({
      dataForm: {
        ...prevState.dataForm,
        program: {
          ...prevState.dataForm.program,
          rules
        },
      }
    }));
  }

  onClickDeleteRule = (index) => {
    const rules = [...this.state.dataForm.program.rules];
    if(index >= 0) {
      rules.splice(index, 1);
      this.setState(prevState => ({
        dataForm: {
          ...prevState.dataForm,
          program: {
            ...prevState.dataForm.program,
            rules
          },
        }
      }));
    }
  }

  onCancel = () => {
    this.props.onClose();
  }

  handleSubmitForm = () => {
    if(this.state.isEdit) {
      this.props.updateCustomAdjustment(this.props.adjustmentId, this.state.dataForm.program, this.props);
    } else {
      this.props.createCustomAdjustment(this.state.dataForm, this.props);
    }
    this.props.onClose();
  }

  renderdataForm = () => {
    let result;
    const { classes } = this.props;
    const title = (this.state.isEdit) ? 'Edit' : 'Create new adjustment';
    result = (
      <Card elevation={0}>
        <CardHeader
          title={
            <h5 className="title">{title}</h5>
          }
        />
        <CardContent>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formOperator">
                  <Form.Label>Name</Form.Label>
                  <Form.Control size="sm" type="text" defaultValue={this.state.dataForm.program.name} onChange={this.onChangeAdjustmentName}/>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formConditions">
                  <Form.Label>Conditions</Form.Label>
                  <ConditionInput value={this.state.dataForm.program.condition} onSelectedConditionFetched={this.handleAdjustmentConditionChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <LoanTypeWhitoutAllSelectBox 
                  getDefaultValue={this.getDefaultLoanType}
                  value={this.state.dataForm.loanType}
                  onChange={this.onChangeLoanType} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ProgramTypeSelectBox
                  value={this.state.dataForm.programType}
                  onChange={this.onChangeProgramType}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLookupParam">
                  <Form.Label>Lookup Param</Form.Label>
                  <Form.Control 
                    size="sm" 
                    as="select"
                    value={this.state.dataForm.program.lookupParam}
                    onChange={this.onChangeAdjustmentLookupParam}
                  >
                    <option value={'LTV'}>LTV</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Rules <AddBoxIcon className={classes.addRuleBtn} color="primary" onClick={() => this.onClickRenderCreateRule()}/></h6>
              </Col>
            </Row>
            {(this.state.dataForm.program.rules || []).map((rule, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <div className="rule-wrapper">
                      <div className="rule-title">
                        <h6>#{index + 1}</h6>
                        <IconButton type="button" color="primary" className="btn-action btn-delete" aria-label="Delete" onClick={() => this.onClickDeleteRule(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <div className="rule-form-wrapper">
                        <AdjustmentTableCreateRule value={rule} key={index} index={index} onAdjustmentRuleFetched={this.handleAdjustmentRuleChange} />
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col>
                <hr></hr>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  size="sm" 
                  className="mr-2"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
                <Button 
                  color="primary" 
                  size="sm"
                  type="button"
                  onClick={this.handleSubmitForm}>Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </CardContent>
      </Card>
    );
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.props.loading ? <Spinner/> : null}
        {this.renderdataForm()}
      </Fragment>
    );
    
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loadingDialog,
    adjustmentDetails: state.adjustment.adjustment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdjustmentDetails: (adjustmentId, objectType) => dispatch(getAdjustmentDetails(adjustmentId, objectType)),
    updateCustomAdjustment: (adjustmentId, adjustment, ownProps) => dispatch(updateCustomAdjustment(adjustmentId, adjustment, ownProps)),
    createCustomAdjustment: (adjustment, ownProps) => dispatch(createCustomAdjustment(adjustment, ownProps)),
  };
};


AdjustmentTableForm.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object,
  onClose: PropTypes.func,
  getAdjustmentDetails: PropTypes.func,
  updateCustomAdjustment: PropTypes.func,
  createCustomAdjustment: PropTypes.func,
  adjustmentDetails: PropTypes.object,
  parentState: PropTypes.object,
  parentProps: PropTypes.object,
  adjustmentType: PropTypes.string,
  adjustmentId: PropTypes.string,
  objectType: PropTypes.string,
  match: PropTypes.any,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(AdjustmentTableForm));
