import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const style = {
  formControl: {
    minWidth: 200,
    marginRight: 10,
  },
};

class AdjustmentSelectBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      adjustmentType: 'default',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    const selectedValue = event.target.value;
    this.setState({
      adjustmentType: selectedValue
    });
    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  }

  renderAdjustmentSelectBox() {
    let result;
    const { classes } = this.props;
    result = (
      <FormControl className={classes.formControl}>
        <InputLabel id="adjustment-type-select-label">Adjustment type</InputLabel>
        <Select
          labelId="adjustment-type-select-label"
          id="adjustment-type-select"
          value={this.state.adjustmentType}
          onChange={this.handleChange}
        >
          <MenuItem value='default'>Default</MenuItem>
          <MenuItem value='custom'>Custom</MenuItem>
        </Select>
      </FormControl>
    );
    return result;
  }

  render() {
    return this.renderAdjustmentSelectBox(this.props);
  }
}

AdjustmentSelectBox.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
};

export default  withStyles(style)(AdjustmentSelectBox);

