import {
  GET_LOANLIMITS
} from '../actions/types';

const INITIAL_STATE = {
  loanLimits: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOANLIMITS:
      return {
        ...state,
        loanLimits: action.loanLimits
      };
    default:
      return state;
  }
};