import React from 'react';
import { Form } from 'react-bootstrap';

class LoanTermSelectBox extends React.Component {
  
  renderLoanTermSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formLoanTerm">
        <Form.Label>Loan Term</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          {...props}
        >
          <option value={'Fixed_15'}>15 Years Fixed</option>
          <option value={'Fixed_30'}>30 Years Fixed</option>
          <option value={'ARM_3_1'}>3 Years Adjustable</option>
          <option value={'ARM_5_1'}>5 Years Adjustable</option>
          <option value={'ARM_7_1'}>7 Years Adjustable</option>
          <option value={'ARM_10_1'}>10 Years Adjustable</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLoanTermSelectBox(this.props);
  }
}


export default LoanTermSelectBox;

