import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const RateService = {
  getRateDetails,
  getRateResults,
};

function getRateDetails(programId) {
  return apiService.get(`v1/rate/${programId}`, { headers: authHeader() });
}

function getRateResults(assumptions) {
  return apiService.post('v1/rate/', assumptions, { headers: authHeader() });
}
