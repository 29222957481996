const conditionFormat = (conditions) => {
  let conditionFormated = {};
  if(conditions && conditions.length === 1) {
    conditionFormated = conditions[0];
  }
  if(conditions && conditions.length > 1) {
    conditionFormated = {
      logical: 'AND',
      rules: conditions
    };
  }
  return conditionFormated;
};
export {
  conditionFormat,
};