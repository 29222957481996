import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const TestingService = {
  getTestSuites,
  getTestingRates,
  getRateTestCase,
  runTestingRate,
  createRateTestCase,
  updateeRateTestCase,
  deleteRateTestCase,
};

function getTestSuites() {
  return apiService.get('v1/testing/rateTestSuite', { headers: authHeader() });
}

function getTestingRates() {
  return apiService.get('v1/testing/rate', { headers: authHeader() });
}

function runTestingRate(params) {
  return apiService.post('v1/testing/run', params, { headers: authHeader() });
}

function getRateTestCase(testCaseId) {
  return apiService.get(`v1/testing/rate/${testCaseId}`, { headers: authHeader() });
}

function createRateTestCase(testSuiteId, scenario, testCase) {
  return apiService.post('v1/testing/rate', { testSuiteId, scenario, testCase }, { headers: authHeader() });
}

function updateeRateTestCase(testCaseId, testCase) {
  return apiService.put(`v1/testing/rate/${testCaseId}`, testCase, { headers: authHeader() });
}

function deleteRateTestCase(testSuiteId, testCaseId) {
  return apiService.delete(`v1/testing/rate/${testSuiteId}/${testCaseId}`, { headers: authHeader() });
}
