import React from 'react';
import { Form } from 'react-bootstrap';

class PropertyTypeSelectBox extends React.Component {
  
  renderPropertyTypeSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formPropertyType">
        <Form.Label>Property Type</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          {...props}
        >
          <option value={'SingleFamily'}>Single Family</option>
          <option value={'Condo'}>Condo</option>
          <option value={'Townhouse'}>Townhouse</option>
          <option value={'Duplex'}>Duplex</option>
          <option value={'Triplex'}>Triplex</option>
          <option value={'Fourplex'}>Fourplex</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderPropertyTypeSelectBox(this.props);
  }
}


export default PropertyTypeSelectBox;

