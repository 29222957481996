import React from 'react';
import { Form } from 'react-bootstrap';

class PropertyUsageSelectBox extends React.Component {
  
  renderPropertyUsageSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formPropertyUsage">
        <Form.Label>Property Usage</Form.Label>
        <Form.Control
          size="sm"
          as="select"
          {...props}
        >
          <option value={'Primary'}>Primary</option>
          <option value={'Secondary'}>Secondary</option>
          <option value={'Investment'}>Investment</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderPropertyUsageSelectBox(this.props);
  }
}


export default PropertyUsageSelectBox;

