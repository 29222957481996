import { ApiService } from './api.service';
import { authHeader } from '../helpers';

let apiService = new ApiService();

export const LoanLimitService = {
  getLoanLimits,
};

function getLoanLimits(searchingKey, pageNumber, pageSize) {
  return apiService.get(`v1/loanlimit?searchingKey=${searchingKey}&pageNumber=${pageNumber}&pageSize=${pageSize}`, { headers: authHeader() });
}
