import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import PageTitle from '../../../../components/PageTitle';
import Wrapper from '../../../../components/Wrapper';
import LenderAutocomplete from '../../../../components/Controls/AutocompleteLender';
import TestingRateTable from '../../../../components/Tables/TestingRateTable';
import TestingRateForm from './Form';
import { getTestingRates } from '../../../../actions';

const style = {
  dialogContent: {
    padding: 0,
  }
};

class TestingRateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateDialog: false,
      lenderIds: [],
    };
    this.handleCloseCreateDialog = this.handleCloseCreateDialog.bind(this);
    this.handleLenderChange = this.handleLenderChange.bind(this);
  }

  componentDidMount() {
    this.props.getTestingRates();
  }

  handleCloseCreateDialog = () => {
    this.setState({
      openCreateDialog: false,
    });
  }

  onClickOpenCreateDialog = () => {
    this.setState({
      openCreateDialog: true,
    });
  }

  handleLenderChange = (lenderIds) => {
    this.setState(prevState => ({
      ...prevState,
      lenderIds
    }));
  }

  renderToolBar = () => {
    let result;
    result = (
      <Grid container spacing={0}>
        <Grid item md={5}>
          <LenderAutocomplete onLenderFetched={this.handleLenderChange} label="Lender" placeholder="Lender" />
        </Grid>
        <Grid item md={5}></Grid>
        <Grid item md={2} className={'text-right'}>
          <Button 
            variant="contained" 
            size="small" 
            color="primary" 
            startIcon={<AddBoxIcon />}
            className={'btn-create'}
            onClick={() => this.onClickOpenCreateDialog()}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    );
    return result;
  }

  
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <PageTitle title='Testing' />
        <Wrapper>
          {this.renderToolBar()}
          <TestingRateTable 
            testingRateTables={this.props.rateTestings}
            selectedLenders={this.state.lenderIds}
            parentProps={this.props}
            parentState={this.state}
          />
        </Wrapper>
        <Dialog
          open={this.state.openCreateDialog}
          onClose={this.handleCloseCreateDialog}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogContent className={classes.dialogContent}>
            <TestingRateForm 
              parentState={this.state}
              parentProps={this.props}
              onClose={this.handleCloseCreateDialog} 
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    rateTestings: state.testing.rateTestings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTestingRates: () => dispatch(getTestingRates()),
  };
};

TestingRateContainer.propTypes = {
  classes: PropTypes.object,
  selectedLenders: PropTypes.array,
  getTestingRates: PropTypes.func,
  rateTestings: PropTypes.array,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(TestingRateContainer));
