import {combineReducers} from 'redux';
import alert from './alert.reducer';
import loanLimit from './loanLimit.reducer';
import zipStateCode from './zipStateCode.reducer';
import lender from './lender.reducer';
import rate from './rate.reducer';
import loanType from './loanType.reducer';
import adjustment from './adjustment.reducer';
import metadata from './metadata.reducer';
import testing from './testing.reducer';
import parsedRate from './parsedRate.reducer';
import spinner from './spinner.reducer';
import authenticate from './authenticate.reducer';


export default combineReducers({
  alert: alert,
  loanLimit: loanLimit,
  zipStateCode: zipStateCode,
  lender: lender,
  rate: rate,
  loanType: loanType,
  adjustment: adjustment,
  metadata: metadata,
  testing: testing,
  parsedRate: parsedRate,
  spinner: spinner,
  authenticate: authenticate,
});
