import {
  GET_RATE,
  GET_RATERESULT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_DIALOG_SPINNER,
  HIDE_DIALOG_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { RateService } from './../services';
  
export const getRateDetails = (programId) => {
  return (dispatch) => {
    dispatch({type: SHOW_DIALOG_SPINNER});
    RateService.getRateDetails(programId).then(response => {
      if (response.success) {
        dispatch({type: HIDE_DIALOG_SPINNER});
        dispatch({
          type: GET_RATE,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_DIALOG_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_DIALOG_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getRateResults = (assumptions) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    RateService.getRateResults(assumptions).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_RATERESULT,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};
