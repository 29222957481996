import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AdjustmentDetails from '../../containers/Admin/Adjustment/AdjustmentDetails';
import AdjustmentTableForm from '../../containers/Admin/Adjustment/Form';
import { deleteCustomAdjustment } from './../../actions';


const style = ({
  dialogContent: {
    padding: 0,
  },
});
class AdjustmentTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: (this.props.page) ? this.props.page : 0,
      rowsPerPage: (this.props.rowsPerPage) ? this.props.rowsPerPage : 50,
      type: this.props.adjustmentType,
      openDialog: false,
      dialogSize: (this.props.dialogSize) ? this.props.dialogSize : 'xl',
      adjustmentId: '',
      dialogContentType: 'details',
      orderBy: this.props.orderBy,
      order: this.props.order,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onClickOpenDialog = this.onClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.createSortHandler = this.createSortHandler.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.adjustmentType !== this.props.adjustmentType) {
      this.setState(prevState => ({
        ...prevState,
        type: this.props.adjustmentType
      }));
    }
    if(prevProps.page !== this.props.page) {
      this.setState(prevProps => ({
        ...prevProps,
        page: this.props.page,
      }));
    }
    if(prevProps.rowsPerPage !== this.props.rowsPerPage) {
      this.setState(prevProps => ({
        ...prevProps,
        rowsPerPage: this.props.rowsPerPage,
      }));
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    }, () => this.props.onTableFetched(this.state));
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }, () => this.props.onTableFetched(this.state));
  }

  onClickOpenDialog = (dialogContentType, dialogContent) => {
    this.setState(prevState => ({
      ...prevState,
      openDialog: true,
      dialogContentType,
      ...dialogContent
    }));
  }
  
  handleCloseDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      openDialog: false,
    }));
  }

  onClickDelete = (adjustmentId) => {
    this.props.deleteCustomAdjustment(adjustmentId, this.props.parentProps, this.props.parentState);
  }

  createSortHandler = (property) => (event) =>  {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState(prevState => ({
      ...prevState,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }), () => this.props.onTableFetched(this.state));
  };

  renderAdjustmentTabe = () => {
    let result;
    if (this.props.adjustmentTables) {
      const adjustmentRows = this.props.adjustmentTables.docs;
      const totalRows = this.props.adjustmentTables.totalDocs;
      if(adjustmentRows && totalRows > 0) {
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, totalRows.length - this.state.page * this.state.rowsPerPage);
        let columns = [
          { id: 'name', label: 'Name', sorting: true},
          { id: 'loan-type', label: 'Loan Type', sorting: false},
          { id: 'type', label: 'Type', sorting: false},
          { id: 'actions', label: 'Actions', align: 'center', sorting: false},
        ];
        if (this.state.type === 'thirdparty') {
          columns = [
            { id: 'name', label: 'Name', sorting: true},
            { id: 'actions', label: 'Actions', align: 'center', sorting: false},
          ];
        }
        result = (
          <TableContainer component={Paper} square={true} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.sorting ? <TableSortLabel
                        active={this.state.orderBy === column.id}
                        direction={this.state.orderBy === column.id ? this.state.order : 'asc'}
                        onClick={this.createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel> : column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {adjustmentRows.map((row, index) => {
                  const viewBtn = (
                    <IconButton type="button" color="primary" className="btn-action" aria-label="View details" onClick={() => this.onClickOpenDialog('details', { adjustmentId: row._id})}>
                      <VisibilityIcon />
                    </IconButton>
                  );
                  const editBtn = (this.state.type === 'custom' || this.state.type === 'thirdparty') ? (
                    <IconButton type="button" color="primary" className="btn-action" aria-label="Edit" onClick={() => this.onClickOpenDialog('edit', { adjustmentId: row._id})}>
                      <EditIcon />
                    </IconButton>
                  ) : '';
                  const deleteBtn = (this.state.type === 'custom' || this.state.type === 'thirdparty') ? (
                    <IconButton type="button" color="primary" className="btn-action btn-delete" aria-label="Delete" onClick={() => this.onClickDelete(row._id)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : '';
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      { this.state.type !== 'thirdparty' ? (
                        <Fragment>
                          <TableCell>{row.loanType}</TableCell>
                          <TableCell>{row.programType}</TableCell>
                        </Fragment>
                      ) : null }
                      <TableCell align="center">
                        {viewBtn}
                        {editBtn}
                        {deleteBtn}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={totalRows}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        );
      }
    }
    return result;
  }

  render() {
    let dialogContent;
    const { classes } = this.props;
    switch (this.state.dialogContentType) {
      case 'edit':
        dialogContent = (
          <AdjustmentTableForm 
            onClose={this.handleCloseDialog} 
            adjustmentId={this.state.adjustmentId} 
            adjustmentType={this.state.type} 
            objectType='edit'
            parentState={this.props.parentState}
            parentProps={this.props.parentProps}
          />
        );
        break;
      default:
        dialogContent = (
          <AdjustmentDetails 
            adjustmentId={this.state.adjustmentId} 
            adjustmentType={this.state.type} 
            objectType='view'
          />
        );
        break;
    }
    return (
      <div>
        {this.renderAdjustmentTabe()}
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          fullWidth={true}
          maxWidth={this.state.dialogSize}
        >
          <DialogContent className={classes.dialogContent}>
            {dialogContent}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

AdjustmentTable.propTypes = {
  classes: PropTypes.object,
  deleteCustomAdjustment: PropTypes.func,
  match: PropTypes.any,
  parentProps: PropTypes.any,
  parentState: PropTypes.any,
  adjustmentTables: PropTypes.object,
  onTableFetched: PropTypes.func,
  adjustmentType: PropTypes.string,
  dialogSize: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  orderBy: PropTypes.string,
  order: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCustomAdjustment: (adjustmentId, ownProps, ownState) => dispatch(deleteCustomAdjustment(adjustmentId, ownProps, ownState))
  };
};


export default compose(
  withStyles(style),
  connect(null, mapDispatchToProps)
)(withRouter(AdjustmentTable));