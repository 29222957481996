import React from 'react';
import { Form } from 'react-bootstrap';

class LockDaysSelectBox extends React.Component {
  
  renderLockDaysSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formDayLock">
        <Form.Label>Day Lock</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          {...props}
        >
          <option value={15}>15</option>
          <option value={30}>30</option>
          <option value={45}>45</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLockDaysSelectBox(this.props);
  }
}


export default LockDaysSelectBox;

