const dateTimeFormat = (value) => {
  const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZone: 'America/Los_Angeles' 
  };
  return new Intl.DateTimeFormat('en-US', options).format(value);
};
  
export {
  dateTimeFormat,
};