import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './Authentication.scss';
import AlertBox from './../../Shared/Alert';
import Spinner from './../../Shared/Spinner';

class AuthenticationLayout extends React.Component {
  render() {
    return (
      <div className="authentication-layout">
        <Grid
          container 
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0} 
          className="authentication-container"
        >
          <Grid item md={4}>
            <AlertBox/>
            <Paper spacing={2} elevation={0} className="main-wrapper">
              {this.props.children}
            </Paper>
          </Grid>
        </Grid>
        {this.props.loading ? <Spinner/> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loading,
  };
};

AuthenticationLayout.propTypes = {
  loading: PropTypes.bool, 
  children: PropTypes.any, 
};

export default withRouter(connect(mapStateToProps, null)(AuthenticationLayout));
