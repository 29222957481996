import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Form, Row, Col } from 'react-bootstrap';
import ConditionInput from '../../../../components/Condition/Input';
import AdjustmentTableRuleAddLookupRate from './AddLookupRate';
import ValueTypeSelectBox from '../../../../components/Controls/SelectBoxValueType';
import '../Style.scss';
const style = ({
  addLookupRateBtn: {
    marginTop: 25,
  }
});

class AdjustmentTableCreateRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruleForm: {
        name: '',
        valueType: 'Percentage',
        condition: {},
        values: [],
      }
    };
    this.onChangeRuleName = this.onChangeRuleName.bind(this);
    this.handleRuleConditionChange = this.handleRuleConditionChange.bind(this);
    this.handleLookupRateChange = this.handleLookupRateChange.bind(this);
    this.onChangeValueType = this.onChangeValueType.bind(this);
  }

  componentDidMount() {
    if(this.props.value && typeof(this.props.value) === 'object' && Object.keys(this.props.value).length) {
      this.setState(prevState => ({
        ...prevState,
        ruleForm: this.props.value
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value) {
      this.setState(prevState => ({
        ...prevState,
        ruleForm: this.props.value
      }));
    }
  }

  onChangeRuleValue = () => {
    this.props.onAdjustmentRuleFetched(this.state.ruleForm, this.props.index);
  }

  onChangeRuleName = (event) => {
    const name = event.target.value;
    this.setState(prevState => ({
      ruleForm: {
        ...prevState.ruleForm,
        name
      }
    }), () => this.onChangeRuleValue());
  }

  onChangeValueType = (event) => {
    const valueType = event.target.value;
    this.setState(prevState => ({
      ruleForm: {
        ...prevState.ruleForm,
        valueType
      }
    }), () => this.onChangeRuleValue());
  }

  handleRuleConditionChange = (condition) => {
    this.setState(prevState => ({
      ruleForm: {
        ...prevState.ruleForm,
        condition
      }
    }), () => this.onChangeRuleValue());
  }

  handleLookupRateChange = (values) => {
    this.setState(prevState => ({
      ruleForm: {
        ...prevState.ruleForm,
        values
      }
    }), () => this.onChangeRuleValue());
  }
  
  renderCreateRule = () => {
    const result = (
      <div className="rule-form">
        <Row>
          <Col>
            <Form.Group controlId={`form-operator-${Date.now()}`}>
              <Form.Label>Name</Form.Label>
              <Form.Control size="sm" type="text" value={this.state.ruleForm.name} onChange={this.onChangeRuleName}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId={`form-conditions-${Date.now()}`}>
              <Form.Label>Conditions</Form.Label>
              <ConditionInput value={this.state.ruleForm.condition} onSelectedConditionFetched={this.handleRuleConditionChange}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <ValueTypeSelectBox
              value={this.state.ruleForm.valueType}
              onChange={this.onChangeValueType}
            />
          </Col>
        </Row>
        <AdjustmentTableRuleAddLookupRate value={this.state.ruleForm.values} valueType={this.state.ruleForm.valueType} onLookupRateFetched={this.handleLookupRateChange} />
      </div>
    );
    return result;
  }

  render() {
    return this.renderCreateRule();
  }

}

AdjustmentTableCreateRule.propTypes = {
  classes: PropTypes.object,
  onAdjustmentRuleFetched: PropTypes.func,
  index: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default withStyles(style)(AdjustmentTableCreateRule);