import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const LoanTypeService = {
  getLoanTypes,
};

function getLoanTypes() {
  return apiService.get('public/v1/loantype', { headers: authHeader() });
}
