import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { getAdjustmentTablesOfLender } from './../../../actions';
import AdjustmentTableForm from './Form';
import AdjustmentSelectBox from '../../../components/Controls/SelectBoxAdjustment';
import AdjustmentTable from '../../../components/Tables/AdjustmentTable';


const style = ({
  dialogContent: {
    padding: 0,
  },
});
class AdjustmentTableContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lenderId: this.props.match.params.id,
      page: 0,
      rowsPerPage: 50,
      searchingKey: '',
      type: 'default',
      orderBy: 'name',
      order: 'asc',
      openCreateDialog: false,
    };
    this.onSearchAdjustment = this.onSearchAdjustment.bind(this);
    this.onClickOpenCreateDialog = this.onClickOpenCreateDialog.bind(this);
    this.handleCloseCreateDialog = this.handleCloseCreateDialog.bind(this);
    this.onAdjustmentTypeChange = this.onAdjustmentTypeChange.bind(this);
    this.handleTableFetched = this.handleTableFetched.bind(this);
  }

  componentDidMount() {
    this.props.getAdjustmentTablesOfLender(this.state.lenderId, this.state.searchingKey, this.state.type, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }
  
  onAdjustmentTypeChange = (adjustmentType) => {
    this.setState(prevState => ({
      ...prevState,
      page: 0,
      type: adjustmentType,
    }));
    this.props.getAdjustmentTablesOfLender(this.state.lenderId, this.state.searchingKey, adjustmentType, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }

  onSearchAdjustment = (event) => {
    this.setState(prevState => ({
      ...prevState,
      page: 0,
    }));
    this.props.getAdjustmentTablesOfLender(this.state.lenderId, this.state.searchingKey, this.state.type, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
    event.preventDefault();
  }

  onClickOpenCreateDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      openCreateDialog: true,
    }));
  }

  handleCloseCreateDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      openCreateDialog: false,
    }));
  }

  handleTableFetched = (fetchedState) => {
    this.setState(prevState => ({
      ...prevState,
      page: fetchedState.page,
      rowsPerPage: fetchedState.rowsPerPage,
      orderBy: fetchedState.orderBy,
      order: fetchedState.order,
    }));
    this.props.getAdjustmentTablesOfLender(this.state.lenderId, this.state.searchingKey, this.state.type, fetchedState.page + 1, fetchedState.rowsPerPage, fetchedState.orderBy, fetchedState.order);
  }

  renderToolBar = () => {
    let result;
    const createBtn = (this.state.type === 'custom') ? (
      <Button 
        variant="contained"
        size="small"
        color="primary"
        startIcon={<AddBoxIcon />}
        className={'btn-create'}
        onClick={() => this.onClickOpenCreateDialog('create')}
      >
        Create
      </Button>
    ) : '';
    result = (
      <Grid container spacing={0}>
        <Grid item md={10}>
          {this.renderSearching()}
        </Grid>
        <Grid item md={2} className={'text-right'}>
          {createBtn}
        </Grid>
      </Grid>
    );
    return result;
  }

  renderSearching = () => {
    let result;
    if (this.props.adjustmentTables && this.props.adjustmentTables.totalDocs >= 0) {
      result = (
        <Paper onSubmit={this.onSearchAdjustment} component="form" square={true} elevation={0}>
          <AdjustmentSelectBox onChange={this.onAdjustmentTypeChange}/>
          <FormControl>
            <TextField
              label="Search"
              id="rate-search-input"
              value={this.state.searchingKey}
              onChange={ e => this.setState({searchingKey: e.target.value})}
            />
          </FormControl>
          <IconButton type="submit" aria-label="search" className="btn search">
            <SearchIcon />
          </IconButton>
        </Paper>
      );
    }
    return result;
  }

  renderAdjustmentTabe = () => {
    let result;
    if (this.props.adjustmentTables && this.props.adjustmentTables.totalDocs >= 0) {
      result = (
        <AdjustmentTable 
          adjustmentTables={this.props.adjustmentTables}
          adjustmentType={this.state.type}
          onTableFetched={this.handleTableFetched}
          parentProps={this.props}
          parentState={this.state}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          orderBy={this.state.orderBy}
          order={this.state.order}
        />
      );
    }
    return result;
  }

  render() {
    const { classes } = this.props;
    const { openCreateDialog, type } = this.state;
    return (
      <div>
        {this.renderToolBar()}
        {this.renderAdjustmentTabe()}
        <Dialog
          open={openCreateDialog}
          onClose={this.handleCloseCreateDialog}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogContent className={classes.dialogContent}>
            <AdjustmentTableForm 
              onClose={this.handleCloseCreateDialog} 
              adjustmentType={type}
              parentState={this.state}
              parentProps={this.props}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    adjustmentTables: state.lender.adjustmentTables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdjustmentTablesOfLender: (
      lenderId,
      searchingKey,
      type,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ) => dispatch(getAdjustmentTablesOfLender(
      lenderId,
      searchingKey,
      type,
      pageNumber,
      pageSize,
      orderBy,
      order,
    )),
  };
};

AdjustmentTableContainer.propTypes = {
  classes: PropTypes.object,
  getAdjustmentTablesOfLender: PropTypes.func,
  match: PropTypes.any,
  adjustmentTables: PropTypes.object,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(AdjustmentTableContainer));
