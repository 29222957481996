import {
  GET_CONDITIONMETADATA
} from './types';
import { showErrorAlert } from './alert.action';
import { MetadataService } from './../services';

export const getConditions = () => {
  return (dispatch) => {
    MetadataService.getCondition().then(response => {
      if (response.success) {
        dispatch({
          type: GET_CONDITIONMETADATA,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};