import React from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
// import {ThroughProvider} from 'react-through';
import PortalLayout from './Layouts/Portal';
import AdminLayout from './Layouts/Admin';
import AuthenticationLayout from './Layouts/Authentication';
import LoanLimitListing from './../../containers/Admin/LoanLimit/Listing';
import ZipStateCodeListing from './../../containers/Admin/ZipStateCode/Listing';
import LenderListing from './../../containers/Admin/Lender/Listing';
import LenderDetails from './../../containers/Admin/Lender/Details';
import LenderThirdpartyDetails from './../../containers/Admin/Lender/ThirdpartyDetails';
import Quote from './../../containers/Portal/Quote/';
import SignUp from './../../containers/Portal/Authentication/SignUp';
import SignIn from './../../containers/Portal/Authentication/SignIn';
import TestingRate from './../../containers/Admin/Testing/Rate/Rate';
import { history } from '../../helpers';
import jwt from 'jwt-decode';


import './style.scss';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => {
    const userStorage = localStorage.getItem('user');
    if (userStorage) {
      const { token } = JSON.parse(userStorage);
      const userInfo = jwt(token); 
      const userRoles = userInfo['cognito:groups'];
      if (userRoles && userRoles.length && userRoles.some(x => x === 'Admin')) {
        return (<Layout><Component {...props} /></Layout>);
      }
    }
    return (<Redirect to='/portal/signin'/>);
  }} />
);

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
);

class App extends React.Component {
  render() {
    return (
      <Router history={ history }>
        <Switch>
          <PrivateRoute exact path='/' layout={PortalLayout} component={Quote} />
          <AppRoute exact path='/portal/signup' layout={AuthenticationLayout} component={SignUp} />
          <AppRoute exact path='/portal/signin' layout={AuthenticationLayout} component={SignIn} />
          <PrivateRoute exact path='/admin' layout={AdminLayout}>
            <Redirect to="/admin/lender" />
          </PrivateRoute>
          <PrivateRoute exact path='/admin/lender' layout={AdminLayout} component={LenderListing} />
          <PrivateRoute path='/admin/lender/local/:id' layout={AdminLayout} component={LenderDetails} />
          <PrivateRoute path='/admin/lender/thirdparty/:id/' layout={AdminLayout} component={LenderThirdpartyDetails} />
          <PrivateRoute exact path='/admin/loanlimit' layout={AdminLayout} component={LoanLimitListing} />
          <PrivateRoute exact path='/admin/zipcode' layout={AdminLayout} component={ZipStateCodeListing} />
          <PrivateRoute exact path='/admin/testing' layout={AdminLayout} component={TestingRate} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    );
  }
}

export default App;