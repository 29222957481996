import {
  GET_LOANLIMITS,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { LoanLimitService } from './../services';

export const getLoanLimits = (searchingKey, pageNumber, pageSize) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    LoanLimitService.getLoanLimits(searchingKey, pageNumber, pageSize).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_LOANLIMITS,
          loanLimits: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};