import React from 'react';
import { Form } from 'react-bootstrap';

class LoanPurposeSelectBox extends React.Component {
  
  renderLoanPurposeSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formLoanPurpose">
        <Form.Label>Loan Purpose</Form.Label>
        <Form.Control 
          size="sm" 
          as="select" 
          {...props}
        >
          <option value={'Refinance'}>Refinance</option>
          <option value={'Purchase'}>Purchase</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLoanPurposeSelectBox(this.props);
  }
}


export default LoanPurposeSelectBox;

