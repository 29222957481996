import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import Wrapper from '../../../components/Wrapper';
import PageTitle from '../../../components/PageTitle';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import { currencyFormat } from './../../../utils';
import { getLoanLimits } from './../../../actions';

class LoanLimitListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searchingKey: 'CALIFORNIA'
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onSearchState = this.onSearchState.bind(this);
  }
  
  componentDidMount() {
    this.props.getLoanLimits(this.state.searchingKey, 1, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
    this.props.getLoanLimits(this.state.searchingKey, newPage + 1, this.state.rowsPerPage);
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
    this.props.getLoanLimits(this.state.searchingKey, this.state.page + 1, event.target.value);
  }

  onSearchState = (event) => {
    this.setState({
      page: 0,
    });
    this.props.getLoanLimits(this.state.searchingKey, 1, this.state.rowsPerPage);
    event.preventDefault();
  }

  renderSearching = () => {
    let result;
    if (this.props.loanLimits && this.props.loanLimits.totalDocs >= 0) {
      result = (
        <Paper onSubmit={this.onSearchState} component="form" square={true} elevation={0}>
          <TextField
            label="State search"
            id="state-search-input"
            value={this.state.searchingKey}
            onChange={ e => this.setState({searchingKey: e.target.value})}
          />
          <IconButton type="submit" aria-label="search" className="btn search">
            <SearchIcon />
          </IconButton>
        </Paper>
      );
    }
    return result;
  }

  renderLoanLimitTable = () => {
    let result;
    if (this.props.loanLimits) {
      const dataRows = this.props.loanLimits.docs;
      const totalRows = this.props.loanLimits.totalDocs;
      if(dataRows && totalRows > 0) {
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, totalRows.length - this.state.page * this.state.rowsPerPage);
        const columns = [
          { id: 'state_name', label: 'State'},
          { id: 'county_name', label: 'County Name'},
          { id: 'state_5_digit_code', label: '5-Digit State Code', align: 'right'},
          { id: 'state_2_digit_code', label: '2-Digit State Code', align: 'right'},
          { id: 'county_3_digit_code', label: '3-Digit County Code', align: 'right'},
          { id: 'metropolitan_designation', label: 'Metropolitan Designation'},
          { id: 'loan_limit_1_unit', label: 'Loan Limit 1 Unit', align: 'right'},
          { id: 'loan_limit_2_unit', label: 'Loan Limit 2 Unit', align: 'right'},
          { id: 'loan_limit_3_unit', label: 'Loan Limit 3 Unit', align: 'right'},
          { id: 'loan_limit_4_unit', label: 'Loan Limit 4 Unit', align: 'right'},
        ];
        result = (
          <div>
            <TableContainer component={Paper} square={true} elevation={0}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRows.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">{row.state.name}</TableCell>
                      <TableCell>{row.county.name}</TableCell>
                      <TableCell align="right">{row.state.fiveDigitCode}</TableCell>
                      <TableCell align="right">{row.state.twoDigitCode}</TableCell>
                      <TableCell align="right">{row.county.threeDigitCode}</TableCell>
                      <TableCell>{row.metropolitanDesignation}</TableCell>
                      <TableCell align="right">{currencyFormat(row.limitValues.year2020.oneUnit)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.limitValues.year2020.twoUnit)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.limitValues.year2020.threeUnit)}</TableCell>
                      <TableCell align="right">{currencyFormat(row.limitValues.year2020.fourUnit)}</TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      count={totalRows}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        );
      }
    }
    return result;
  }

  render() {
    return (
      <div>
        <PageTitle title='Loan limit' />
        <Wrapper>
          {this.renderSearching()}
          {this.renderLoanLimitTable()}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loanLimits: state.loanLimit.loanLimits,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoanLimits: (searchingKey, pageNumber, pageSize) => dispatch(getLoanLimits(searchingKey, pageNumber, pageSize))
  };
};

LoanLimitListing.propTypes = {
  loanLimits: PropTypes.any, 
  getLoanLimits: PropTypes.func 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanLimitListing));