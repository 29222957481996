import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Wrapper from '../../../components/Wrapper';
import PageTitle from '../../../components/PageTitle';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getZipStateCodes } from './../../../actions';

class ZipStateCodeListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  componentDidMount() {
    this.props.getZipStateCodes(1, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
    this.props.getZipStateCodes(newPage + 1, this.state.rowsPerPage);
  }
  
  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
    this.props.getZipStateCodes(this.state.page + 1, event.target.value);
  }

  getZipStateCodeTable = () => {
    let result;
    if (this.props.zipStateCodes) {
      const dataRows = this.props.zipStateCodes.docs;
      const totalRows = this.props.zipStateCodes.totalDocs;
      if(dataRows && totalRows > 0) {
        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, totalRows.length - this.state.page * this.state.rowsPerPage);
        const columns = [
          { id: 'zip_code', label: 'Zipcode', align: 'right'},
          { id: 'city', label: 'City', align: 'right'},
          { id: 'state_code', label: '5-Digit State Code', align: 'right'},
          { id: 'state_name', label: 'State', align: 'right'},
          { id: 'state_abbreviation', label: 'State Abbreviation', align: 'right'},
        ];
        result = (
          <TableContainer component={Paper} square={true} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRows.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row" align='right'>{row.zipcode}</TableCell>
                    <TableCell align='right'>{row.city}</TableCell>
                    <TableCell align='right'>{row.statecode}</TableCell>
                    <TableCell align='right'>{row.statename}</TableCell>
                    <TableCell align='right'>{row.state}</TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={totalRows}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        );
      }
    }
    return result;
  }

  render() {
    return (
      <Fragment>
        <PageTitle title='Zipcode' />
        <Wrapper>
          {this.getZipStateCodeTable()}
        </Wrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    zipStateCodes: state.zipStateCode.zipStateCodes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getZipStateCodes: (pageNumber, pageSize ) => dispatch(getZipStateCodes(pageNumber, pageSize))
  };
};

ZipStateCodeListing.propTypes = {
  zipStateCodes: PropTypes.any, 
  getZipStateCodes: PropTypes.func,
  classes: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZipStateCodeListing));