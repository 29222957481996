import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import PageTitle from './../../../components/PageTitle';
import MortgageResult from '../../../components/MortgageResult/Result';
import PortalSidebar from './../../../components/Sidebar/Portal';

class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assumptions: {}
    };
    this.handleAssumptionChange = this.handleAssumptionChange.bind(this);
  }

  handleAssumptionChange(assumptions) {
    this.setState({
      assumptions
    });
  }

  render() {
    return (
      <Grid container spacing={0} >
        <Grid item md={2}>
          <PortalSidebar onAssumptionFetched={this.handleAssumptionChange} />
        </Grid>
        <Grid item md={10} >
          <div className="main-wrapper">
            <PageTitle 
              title='Your customized quote'
              subTitle="Congratulations! Today's best rate deal available from the network of 50+ lenders."
            />
            <MortgageResult assumptions={this.state.assumptions}/>
          </div>
        </Grid>
      </Grid>
    );
  }
}


Quote.propTypes = {
  children: PropTypes.any,
  onAssumptionFetched: PropTypes.func,
};

export default Quote;