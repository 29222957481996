function paramParser(param, conditionMetadata) {
  let result = param;
  const conditionParamData = conditionMetadata.find(x => x.nameTag === param);
  if (conditionParamData && typeof (conditionParamData) === 'object') {
    result = (conditionParamData.name) ? conditionParamData.name : param;
  }
  return result;
}

function operatorParser(operator, conditionMetadata) {
  let result = operator;
  const conditionOperatorData = conditionMetadata.find(x => x.nameTag === 'Operator');
  if (conditionOperatorData && typeof (conditionOperatorData) === 'object') {
    const operators = (conditionOperatorData.values) ? conditionOperatorData.values : [];
    if (operators.length) {
      const operatorValues = operators.find(x => x.value === operator);
      result = (operatorValues.name) ? operatorValues.name : operator;
    }
  }
  return result;
}

function valueParser(param, value, conditionMetadata) {
  let result = value;
  const conditionParamData = conditionMetadata.find(x => x.nameTag === param);
  if (conditionParamData && typeof (conditionParamData) === 'object') {
    const conditionValues = (conditionParamData.values) ? conditionParamData.values : [];
    if (conditionValues.length) {
      const conditionValue = conditionValues.find(x => x.value === value);
      result = (conditionValue && typeof (conditionValue) === 'object') ? conditionValue.name.toString() : value;
    } else {
      const { valueType } = conditionParamData;
      switch (valueType) {
        case 'currency':
          result = new Intl.NumberFormat(
            'en-US',
            {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            },
          ).format(value);
          break;
        default:
          result = value;
          break;
      }
    }
  } else {
    result = value;
  }
  return result;
}

const conditionParse = (condition, conditionMetadata) => {
  if(condition && typeof(condition) === 'object' && Object.keys(condition).length) {
    const { param, operator, value } = condition;
    const paramParse = paramParser(param, conditionMetadata);
    const operatorParse = operatorParser(operator, conditionMetadata);
    const valueParse = valueParser(param, value, conditionMetadata);
    const label = `${paramParse} ${operatorParse} ${valueParse}`;
    return label;
  }
  return;
};

export {
  conditionParse,
};