import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Spinner from '../../../components/Shared/Spinner';
import { getRateDetails } from './../../../actions';
import ConditionView from '../../../components/Condition/View';

class RateTableDetails extends Component {

  componentDidMount() {
    this.props.getRateDetails(this.props.programId);
  }

  renderRateTabeDetails() {
    let result;
    if (this.props.rate) {
      const rateRows = this.props.rate.lookupRates;
      const condition = this.props.rate.condition;
      const totalRows = (rateRows) ? rateRows.length : 0;
      if(rateRows && totalRows > 0) {
        let columns = [];
        for (const key in rateRows[0]) {
          if(/lock/.test(key) && /Day/.test(key)) {
            const label = key.replace(/lock/g,'').replace(/Day/g,' Day');
            columns = [...columns, { id: key, label: label, align: 'right'}];
          } else if(key === 'rate') {
            columns = [...columns, { id: key, label: 'Rate', align: 'right'}];
          } else {
            columns = [...columns, { id: key, label: key, align: 'right'}];
          }
        }
        const programCondition = (condition && Object.keys(condition).length) ?(<ConditionView condition={condition} />) : '';
        result = (
          <div>
            <h5>{this.props.rate.name}</h5>
            {programCondition}
            <TableContainer component={Paper} square={true} elevation={0}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rateRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="right">{row[Object.keys(row)[0]]}</TableCell>
                      <TableCell align="right">{row[Object.keys(row)[1]]}</TableCell>
                      <TableCell align="right">{row[Object.keys(row)[2]]}</TableCell>
                      <TableCell align="right">{row[Object.keys(row)[3]]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      }
    }
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.props.loading ? <Spinner/> : null}
        {this.renderRateTabeDetails()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loadingDialog,
    rate: state.rate.rate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateDetails: (rateID) => dispatch(getRateDetails(rateID))
  };
};

RateTableDetails.propTypes = {
  loading: PropTypes.bool,
  programId: PropTypes.string,
  getRateDetails: PropTypes.func,
  rate: PropTypes.any,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RateTableDetails));
