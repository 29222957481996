import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import LoanTypeWhitoutAllSelectBox from '../Controls/SelectBoxLoanTypeWhithoutAll';
import LenderAutocomplete from '../Controls/AutocompleteLender';
import LTVInput from '../Controls/InputLTV';
class Assumptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ltv: 0,
      assumptionForm : {
        loanPurpose: 'Refinance',
        loanAmount: 500000,
        loanType: '',
        loanTerm: 'Fixed_30',
        propertyValue: 1000000,
        propertyType: 'SingleFamily',
        propertyUsage: 'Primary',
        creditScore: 760,
        cashOut: false,
        zipCode: '95111',
        lockDays: 30,
        lenders: [],
      }
    };
    this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
    this.onChangePropertyType = this.onChangePropertyType.bind(this);
    this.onChangePropertyUsage = this.onChangePropertyUsage.bind(this);
    this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
    this.onChangePropertyValue = this.onChangePropertyValue.bind(this);
    this.onChangeCreditScore = this.onChangeCreditScore.bind(this);
    this.onChangeZipcode = this.onChangeZipcode.bind(this);
    this.onChangeCashOut = this.onChangeCashOut.bind(this);
    this.onChangeLoanTerm = this.onChangeLoanTerm.bind(this);
    this.onChangeLoanType = this.onChangeLoanType.bind(this);
    this.handleLenderChange = this.handleLenderChange.bind(this);
    this.getDefaultLoanType = this.getDefaultLoanType.bind(this);
    this.onSubmitAssumptionForm = this.onSubmitAssumptionForm.bind(this);
    this.calculateLTV = this.calculateLTV.bind(this);
  }

  componentDidMount() {
    const { loanAmount, propertyValue } = this.state.assumptionForm;
    this.calculateLTV({ loanAmount, propertyValue });
  }

  calculateLTV = ({ loanAmount, propertyValue }) => {
    let roundedLTV = 0;
    if (loanAmount && propertyValue) {
      const ltv = loanAmount * 100 / propertyValue;
      const digit = 10 ** 2;
      roundedLTV = Math.round((ltv + Number.EPSILON) * digit) / digit;
    }
    this.setState(prevState => ({
      ...prevState,
      ltv: roundedLTV,
    }));
  };

  onChangeLoanPurpose(event) {
    const valueChange = event.target.value;
    const isRefinance = (valueChange === 'Cashout') ? true : false;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        loanPurpose: (valueChange === 'Cashout') ? 'Refinance' : valueChange,
        cashOut: isRefinance
      }
    }));
  }

  onChangeLoanAmount(values) {
    const { value } = values;
    const { propertyValue } = this.state.assumptionForm;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        loanAmount: parseFloat(value)
      }
    }));
    this.calculateLTV({ loanAmount: parseFloat(value), propertyValue });
  }

  onChangePropertyValue(values) {
    const { value } = values;
    const { loanAmount } = this.state.assumptionForm;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        propertyValue: parseFloat(value)
      }
    }));
    this.calculateLTV({ loanAmount, propertyValue: parseFloat(value) });
  }

  onChangeCreditScore(event) {
    const valueChange = +event.target.value;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        creditScore: valueChange
      }
    }));
  }

  onChangeZipcode(event) {
    const valueChange = event.target.value;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        zipCode: valueChange
      }
    }));
  }

  onChangeCashOut(event) {
    let valueChange = false;
    if((/true/i).test(event.target.value)) {
      valueChange = true;
    }    
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        cashOut: valueChange
      }
    }));
  }

  onChangePropertyType(event) {
    const valueChange = event.target.value;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        propertyType: valueChange
      }
    }));
  }

  onChangePropertyUsage(event) {
    const valueChange = event.target.value;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        propertyUsage: valueChange
      }
    }));
  }

  onChangeLoanTerm(event) {
    const valueChange = event.target.value;
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        loanTerm: valueChange
      }
    }));
  }

  onChangeLoanType(loanType) {
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        loanType
      }
    }));
  }

  handleLenderChange(lenderIds) {
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        lenders: lenderIds
      }
    }));
  }

  getDefaultLoanType(loanType) {
    this.setState(prevState => ({
      assumptionForm: {
        ...prevState.assumptionForm,
        loanType,
      }
    }));
  }

  onSubmitAssumptionForm(event) {
    event.preventDefault();
    this.props.onAssumptionFetched(this.state.assumptionForm);
  }

  renderAssumptionForm() {
    const result = (
      <div>
        <h4>Assumptions</h4>
        <Form onSubmit={this.onSubmitAssumptionForm}>
          <Form.Group controlId="formLoanPurpose">
            <Form.Label>Loan Purpose</Form.Label>
            <Form.Control 
              size="sm" 
              as="select" 
              onChange={this.onChangeLoanPurpose}
              defaultValue={this.state.assumptionForm.loanPurpose}
            >
              <option value={'Refinance'}>Refinance</option>
              <option value={'Cashout'}>Refinance - Cash Out</option>
              <option value={'Purchase'}>Purchase</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formLoanAmount">
            <Form.Label>Loan Amount</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="formLoanAmount">$</InputGroup.Text>
              </InputGroup.Prepend>
              <NumberFormat 
                customInput={FormControl} 
                value={this.state.assumptionForm.loanAmount} 
                thousandSeparator={true} 
                onValueChange={this.onChangeLoanAmount} 
              />
            </InputGroup>
          </Form.Group>

          <Grid container spacing={0} >
            <Grid item md={8}>
              <Form.Group controlId="formPropertyValue">
                <Form.Label>Property Value</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="formPropertyValue">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <NumberFormat 
                    customInput={FormControl} 
                    value={this.state.assumptionForm.propertyValue} 
                    thousandSeparator={true} 
                    onValueChange={this.onChangePropertyValue} 
                  />
                </InputGroup>
              </Form.Group>
            </Grid>
            <Grid item md={4} className="pl-1">
              <LTVInput value={this.state.ltv} disabled={true} />
            </Grid>
          </Grid>

          <Form.Group controlId="formLoanTerm">
            <Form.Label>Loan Term</Form.Label>
            <Form.Control 
              size="sm"
              as="select"
              onChange={this.onChangeLoanTerm}
              defaultValue={this.state.assumptionForm.loanTerm}
            >
              <option value={'Fixed_10'}>10 Years Fixed</option>
              <option value={'Fixed_15'}>15 Years Fixed</option>
              <option value={'Fixed_20'}>20 Years Fixed</option>
              <option value={'Fixed_25'}>25 Years Fixed</option>
              <option value={'Fixed_30'}>30 Years Fixed</option>
              <option value={'ARM_5_1'}>5 Years Adjustable</option>
              <option value={'ARM_7_1'}>7 Years Adjustable</option>
              <option value={'ARM_10_1'}>10 Years Adjustable</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formZipcode">
            <Form.Label>Zipcode</Form.Label>
            <Form.Control size="sm" type="text" value={this.state.assumptionForm.zipCode} onChange={this.onChangeZipcode}/>
          </Form.Group>
          
          <Form.Group controlId="formCreditScore">
            <Form.Label>Credit Score</Form.Label>
            <Form.Control 
              size="sm" 
              as="select"
              name="creditScore"
              value={this.state.assumptionForm.creditScore}
              onChange={this.onChangeCreditScore}
            >
              <option value={780}>780+ (Excellent+)</option>
              <option value={760}>760-779 (Excellent)</option>
              <option value={740}>740-759 (Excellent-)</option>
              <option value={720}>720-739 (Good+)</option>
              <option value={700}>700-719 (Good)</option>
              <option value={680}>680-699 (Good-)</option>
              <option value={660}>660-679 (Fair)</option>
              <option value={659}>659- (Poor)</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPropertyType">
            <Form.Label>Property Type</Form.Label>
            <Form.Control 
              size="sm" 
              as="select" 
              onChange={this.onChangePropertyType}
              defaultValue={this.state.assumptionForm.propertyType}
            >
              <option value={'SingleFamily'}>Single Family</option>
              <option value={'Condo'}>Condo</option>
              <option value={'Townhouse'}>Townhouse</option>
              <option value={'Duplex'}>Duplex</option>
              <option value={'Triplex'}>Triplex</option>
              <option value={'Fourplex'}>Fourplex</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPropertyUsage">
            <Form.Label>Property Usage</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              onChange={this.onChangePropertyUsage}
              defaultValue={this.state.assumptionForm.propertyUsage}
            >
              <option value={'Primary'}>Primary</option>
              <option value={'Secondary'}>Secondary</option>
              <option value={'Investment'}>Investment</option>
            </Form.Control>
          </Form.Group>
          <LoanTypeWhitoutAllSelectBox 
            getDefaultValue={this.getDefaultLoanType}
            onChange={this.onChangeLoanType}
          />
          <Form.Group controlId="formLender">
            <Form.Label>Lender</Form.Label>
            <LenderAutocomplete onLenderFetched={this.handleLenderChange}/>
          </Form.Group>
          <Button variant="primary" type="submit" size="sm">
            Update
          </Button>
        </Form>
      </div>
    );

    return result;
  }

  render() {
    return this.renderAssumptionForm();
  }
}

Assumptions.propTypes = {
  onAssumptionFetched: PropTypes.func,
};

export default Assumptions;
