import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

class AlertBox extends Component {
  render() {
    if (this.props.alert.showAlert === true) {
      const alertHeadingText = this.props.alert.headingAlert;
      const alertHeading = (alertHeadingText && alertHeadingText.length) ? (
        <Alert.Heading>{this.props.alert.headingAlert}</Alert.Heading>
      ) : '';
      return (
        <Alert variant={this.props.alert.colorAlert}>
          {alertHeading}
          {this.props.alert.messageAlert}
        </Alert>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert.alert,
  };
};

const mapDispatchToProps = () => {
  return {};
};

AlertBox.propTypes = {
  alert: PropTypes.object 
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertBox);
