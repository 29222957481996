import {
  GET_ADJUSTMENT,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_DIALOG_SPINNER,
  HIDE_DIALOG_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { AdjustmentService } from './../services';

export const getAdjustmentDetails = (adjustmentId, objectType) => {
  return (dispatch) => {
    dispatch({type: SHOW_DIALOG_SPINNER});
    AdjustmentService.getAdjustmentDetails(adjustmentId, objectType).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_DIALOG_SPINNER});
        dispatch({
          type: GET_ADJUSTMENT,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_DIALOG_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_DIALOG_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const createCustomAdjustment = (adjustment, ownProps) => {
  const parentState = ownProps.parentState;
  const parentProps = ownProps.parentProps;
  const { lenderId, searchingKey, type, page, rowsPerPage } = parentState
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    AdjustmentService.createCustomAdjustment(adjustment, type).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_SPINNER});
        parentProps.getAdjustmentTablesOfLender(lenderId, searchingKey, type, page + 1, rowsPerPage);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const updateCustomAdjustment = (adjustmentId, adjustment, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    AdjustmentService.updateCustomAdjustment(adjustmentId, adjustment).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_SPINNER});
        const parentState = ownProps.parentState;
        const parentProps = ownProps.parentProps;
        parentProps.getAdjustmentTablesOfLender(parentState.lenderId, parentState.searchingKey, parentState.type, parentState.page + 1, parentState.rowsPerPage);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const deleteCustomAdjustment = (adjustmentId, ownProps, ownState) => {
  const { lenderId, searchingKey, type, page, rowsPerPage } = ownState;
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    AdjustmentService.deleteCustomAdjustment(adjustmentId, type, lenderId).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_SPINNER});
        ownProps.getAdjustmentTablesOfLender(lenderId, searchingKey, type, page + 1, rowsPerPage);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};
