import {
  PARSERATEFILE_SUCCESS,
  PARSERATEFILE_FAILURE,
  GET_PARSEDRATETABLES,
  GET_PARSEDADJUSTMENTTABLES,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { ParsedRateService } from './../services';
  
export const parseRateFile = (filename, lender) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    ParsedRateService.parseRateFile(filename, lender).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: PARSERATEFILE_SUCCESS,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      if(error.toString() === 'Error: Network Error') {
        showErrorAlert(dispatch, 'Failed operation' + error.toString());
      } else {
        dispatch({
          type: PARSERATEFILE_FAILURE,
          data: (error.response.data.error) || ''
        });
      }
    });
  };
};

export const saveParsedRateFile = (parsedRateId) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    ParsedRateService.saveParsedRateFile(parsedRateId).then(response => {
      if (response) {
        dispatch({type: HIDE_SPINNER});
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getParsedRateTables = (parsedId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    ParsedRateService.getParsedRateTables(parsedId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order).then(response => {
      if (response && response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_PARSEDRATETABLES,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getParsedAdjustmentTables = (parsedId, searchingKey, pageNumber, pageSize, orderBy, order) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    ParsedRateService.getParsedAdjustmentTables(parsedId, searchingKey, pageNumber, pageSize, orderBy, order).then(response => {
      if (response && response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_PARSEDADJUSTMENTTABLES,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};
