function ltvCalculate(loanAmount, propertyValue) {
  let ltv = 0;
  ltv = Math.round((+loanAmount/ +propertyValue) * 100);
  return +ltv;
}

function propertyValueCalculate(loanAmount, ltv) {
  let propertyValue = 0;
  propertyValue = Math.round((+loanAmount * 100) / +ltv);
  return +propertyValue;
}

export {
  ltvCalculate,
  propertyValueCalculate,
};