import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { getParsedAdjustmentTables } from './../../../actions';
import AdjustmentTable from '../../../components/Tables/AdjustmentTable';


const style = ({
  dialogContent: {
    padding: 0,
  },
});
class ParseAdjustmentTableContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      parsedId: this.props.parsedId,
      page: 0,
      rowsPerPage: 50,
      searchingKey: '',
      orderBy: 'name',
      order: 'asc',
    };
    this.handleTableFetched = this.handleTableFetched.bind(this);
  }

  componentDidMount() {
    this.props.getParsedAdjustmentTables(this.state.parsedId, this.state.searchingKey, 1, this.state.rowsPerPage, this.state.orderBy, this.state.order);
  }
  
  handleTableFetched(fetchedState) {
    this.setState(prevState => ({
      ...prevState,
      page: fetchedState.page,
      rowsPerPage: fetchedState.rowsPerPage,
      orderBy: fetchedState.orderBy,
      order: fetchedState.order,
    }));
    this.props.getParsedAdjustmentTables(this.state.parsedId, this.state.searchingKey, fetchedState.page + 1, fetchedState.rowsPerPage, fetchedState.orderBy, fetchedState.order);
  }

  renderAdjustmentTabe() {
    let result;
    if (this.props.adjustmentTables && this.props.adjustmentTables.totalDocs >= 0) {
      result = (
        <AdjustmentTable 
          adjustmentTables={this.props.adjustmentTables}
          onTableFetched={this.handleTableFetched}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          orderBy={this.state.orderBy}
          order={this.state.order}
          dialogSize="md"
        />
      );
    }
    return result;
  }

  render() {
    return (
      <div>
        {this.renderAdjustmentTabe()}
      </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    adjustmentTables: state.parsedRate.parsedAdjustmentTables,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getParsedAdjustmentTables: (
      parsedId,
      searchingKey,
      type,
      pageNumber,
      pageSize,
      orderBy,
      order,
    ) => dispatch(getParsedAdjustmentTables(
      parsedId,
      searchingKey,
      type,
      pageNumber,
      pageSize,
      orderBy,
      order,
    )),
  };
};

ParseAdjustmentTableContainer.propTypes = {
  classes: PropTypes.object,
  getParsedAdjustmentTables: PropTypes.func,
  match: PropTypes.any,
  adjustmentTables: PropTypes.object,
  parsedId: PropTypes.string,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ParseAdjustmentTableContainer));
