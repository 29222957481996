import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const MetadataService = {
  getCondition,
};

function getCondition() {
  return apiService.get('v1/metadata/condition', { headers: authHeader() });
}
