import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getLoanTypes } from '../../actions';

class LoanTypeWhitoutAllSelectBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getLoanTypes();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.loanTypes !== this.props.loanTypes) {
      const loanTypes = (this.props.loanTypes.length) ? this.props.loanTypes : [];
      if(loanTypes.length) {
        const conventionalIndex = loanTypes.findIndex(loantype => loantype.name === 'Conventional');
        if(conventionalIndex >= 0) {
          const value = loanTypes[conventionalIndex]._id;
          this.setState({
            value
          });
          this.props.getDefaultValue(value);
        }
      }
    }
    if(prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  handleChange(event){
    const selectedValue = event.target.value;
    this.setState({
      value: selectedValue
    });
    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  }

  renderLoanTypeWhitoutAllSelectBox() {
    let result;
    if(this.props.loanTypes) {
      const loanTypes = (this.props.loanTypes) ? this.props.loanTypes : [];
      result = (
        <Form.Group controlId="formLoanType">
          <Form.Label>Loan Type</Form.Label>
          <Form.Control 
            size="sm"
            as="select"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {(loanTypes.length) ? loanTypes.map((loantype, index) => (
              <option key={index} value={loantype._id}>{loantype.name}</option>
            )) : <option></option>}
          </Form.Control>
        </Form.Group>
      );
    }
    return result;
  }

  render() {
    return this.renderLoanTypeWhitoutAllSelectBox();
  }
}

const mapStateToProps = state => {
  return {
    loanTypes: state.loanType.loanTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoanTypes: () => dispatch(getLoanTypes())
  };
};

LoanTypeWhitoutAllSelectBox.propTypes = {
  loanTypes: PropTypes.any,
  getLoanTypes: PropTypes.func,
  onChange: PropTypes.func,
  getDefaultValue: PropTypes.func,
  value: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoanTypeWhitoutAllSelectBox));

