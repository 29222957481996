import { ApiService } from './api.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();

export const ZipStateCodeService = {
  getZipStateCodes,
};

function getZipStateCodes(pageNumber, pageSize) {
  return apiService.get(`v1/zipcode?pageNumber=${pageNumber}&pageSize=${pageSize}`, { headers: authHeader() });
}
