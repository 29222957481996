import { ApiService } from './api.service';
import { RateParserApiService } from './api.rateParser.service';
import { authHeader } from '../helpers';
let apiService = new ApiService();
let rateParserApiService = new RateParserApiService();

export const ParsedRateService = {
  parseRateFile,
  saveParsedRateFile,
  getParsedRateTables,
  getParsedAdjustmentTables,
};

function parseRateFile(filename, lender) {
  return rateParserApiService.post('parse', { filename, lender }, { headers: authHeader() });
}

function saveParsedRateFile(parsedRateId) {
  return rateParserApiService.post('save', { parsedRateId }, { headers: authHeader() });
}

function getParsedRateTables(parsedId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order) {
  return apiService.get(
    `v1/parsedrate/${parsedId}/rateTables?searchingKey=${searchingKey}&loanTypeId=${loanTypeId}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}`,
    { headers: authHeader() }
  );
}

function getParsedAdjustmentTables(parsedId, searchingKey, pageNumber, pageSize, orderBy, order) {
  return apiService.get(
    `v1/parsedrate/${parsedId}/adjustmentTables?searchingKey=${searchingKey}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy=${orderBy}&order=${order}`,
    { headers: authHeader() }
  );
}
