import {
  GET_LENDER,
  GET_LENDERS,
  GET_ALLLENDER,
  GET_ALL_PUBLISHED_LENDERS,
  GET_RATEOFLENDER,
  GET_ADJUSTMENTOFLENDER,
  GET_RATEFILES,
  UPDATE_PUBLISHED_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  lenders: {},
  allLender: [],
  rateFiles: [],
  rateTables: {},
  adjustmentTables: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RATEOFLENDER:
      return {
        ...state,
        rateTables: action.data
      };
    case GET_ADJUSTMENTOFLENDER:
      return {
        ...state,
        adjustmentTables: action.data
      };
    case GET_ALLLENDER:
      return {
        ...state,
        allLender: action.data
      };
    case GET_ALL_PUBLISHED_LENDERS:
      return {
        ...state,
        allPublisherLender: action.data
      };
    case GET_LENDERS:
      return {
        ...state,
        lenders: action.data
      };
    case GET_LENDER:
      return {
        ...state,
        lender: action.data
      };
    case GET_RATEFILES:
      return {
        ...state,
        rateFiles: action.data
      };
    case UPDATE_PUBLISHED_SUCCESS:
      return {
        ...state,
        updatedLender: action.data
      };
    default:
      return state;
  }
};