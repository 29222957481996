import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Logo from '../../../assets/images/logo.png';
import './style.scss';

class Header extends React.Component {
  render() {
    return (
      <header className="header-wrapper">
        <nav className="navbar-wrapper sticky-top">
          <div className="logo-wrapper">
            <Link to="/">
              <Image src={Logo} className="logo" />
            </Link>
          </div>
          <div className="content-wrapper">
            <div className="content-wrapper-left"></div>
            <div className="content-wrapper-right">
              <Link className="btn btn-primary" to="/portal/signin" role="button">Log out</Link>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
