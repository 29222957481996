import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './style.scss';

class Wrapper extends React.Component {
  render() {
    return (
      <Card className="main-content">
        <Card.Body>
          {this.props.children}
        </Card.Body>
      </Card>
    );
  }
}

Wrapper.propTypes = {
  children: PropTypes.any, 
};

export default Wrapper;
