import React from 'react';
import { Form } from 'react-bootstrap';

class ProgramTypeSelectBox extends React.Component {
  
  renderProgramTypeSelectBox(props) {
    let result;
    result = (
      <Form.Group controlId="formProgramType">
        <Form.Label>Program Type</Form.Label>
        <Form.Control 
          size="sm" 
          as="select"
          {...props}
        >
          <option value={'Conforming'}>Conforming/ High Balance</option>
          <option value={'Jumbo'}>Jumbo</option>
        </Form.Control>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderProgramTypeSelectBox(this.props);
  }
}


export default ProgramTypeSelectBox;

