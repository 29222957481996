import React, {Component} from 'react';
import SignUpForm from './../../../components/SignUp/Form';

class SignUp extends Component {
  render() {
    return (
      <div>
        <h3 className="form-title">Create an account</h3>
        <SignUpForm />
      </div>
    );
  }
}

export default SignUp;