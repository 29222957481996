import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LoanTermSelectBox from '../../../../components/Controls/SelectBoxLoanTerm';
import LoanPurposeSelectBox from '../../../../components/Controls/SelectBoxLoanPurpose';
import PropertyTypeSelectBox from '../../../../components/Controls/SelectBoxPropertyType';
import PropertyUsageSelectBox from '../../../../components/Controls/SelectBoxPropertyUsage';
import CashOutSelectBox from '../../../../components/Controls/SelectBoxCashout';
import ZipcodeInput from '../../../../components/Controls/InputZipcode';
import LoanAmountInput from '../../../../components/Controls/InputLoanAmount';
import PropertyValueInput from '../../../../components/Controls/InputPropertyValue';
import CreditScoreInput from '../../../../components/Controls/InputCreditScore';
import LTVInput from '../../../../components/Controls/InputLTV';
import LoanTypeWhitoutAllSelectBox from '../../../../components/Controls/SelectBoxLoanTypeWhithoutAll';
import LockDaysSelectBox from '../../../../components/Controls/SelectBoxLockDays';
import TestSuiteSelectBox from '../../../../components/Controls/SelectBoxTestSuite';
import ScenarioInput from '../../../../components/Controls/InputScenario';
import Spinner from '../../../../components/Shared/Spinner';
import { getTestingRate, createRateTestCase, updateRateTestCase } from '../../../../actions';
import { ltvCalculate, propertyValueCalculate } from '../../../../utils';

class TestingRateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      testSuite: '',
      scenario: '',
      FormData : {
        loanPurpose: 'Refinance',
        loanAmount: 400000,
        loanType: null,
        loanTerm: 'Fixed_30',
        ltv: 40,
        propertyValue: 1000000,
        propertyType: 'SingleFamily',
        propertyUsage: 'Primary',
        creditScore: 700,
        cashOut: false,
        zipCode: '95111',
        lockDays: 30,
      }
    };
    this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);
    this.onChangeLoanPurpose = this.onChangeLoanPurpose.bind(this);
    this.onChangePropertyValue = this.onChangePropertyValue.bind(this);
    this.onChangeLoanTerm = this.onChangeLoanTerm.bind(this);
    this.onChangeCashOut = this.onChangeCashOut.bind(this);
    this.onChangeZipcode = this.onChangeZipcode.bind(this);
    this.onChangeCreditScore = this.onChangeCreditScore.bind(this);
    this.onChangePropertyType = this.onChangePropertyType.bind(this);
    this.onChangePropertyUsage = this.onChangePropertyUsage.bind(this);
    this.onChangeLoanType = this.onChangeLoanType.bind(this);
    this.onChangeLockDays = this.onChangeLockDays.bind(this);
    this.onChangeTestSuite = this.onChangeTestSuite.bind(this);
    this.onChangeScenario = this.onChangeScenario.bind(this);
    this.onChangeLTV = this.onChangeLTV.bind(this);
    this.getDefaultLoanType = this.getDefaultLoanType.bind(this);
    this.getDefaultTestSuite = this.getDefaultTestSuite.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    if(this.props.testCaseId) {
      this.props.getTestingRate(this.props.testCaseId);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.rateTestingDetails !== this.props.rateTestingDetails) {
      const rateTestingDetails = this.props.rateTestingDetails;
      if(rateTestingDetails && Object.keys(rateTestingDetails).length) {
        const rateTestingPrams = rateTestingDetails.params;
        const rateTestingScenario = rateTestingDetails.scenario;
        this.setState(prevState => ({
          ...prevState,
          isEdit: true,
          scenario: rateTestingScenario,
          FormData: rateTestingPrams,
        }));
      }
    }
  }

  onChangeTestSuite(testSuite) {
    this.setState(prevState => ({
      ...prevState,
      testSuite,
    }));
  }

  onChangeScenario(event) {
    const scenario = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      scenario,
    }));
  }

  onChangeLoanPurpose(event) {
    const loanPurpose = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        loanPurpose,
      }
    }));
  }

  onChangeLoanAmount(event) {
    const loanAmount = +event.target.value;
    const propertyValue = propertyValueCalculate(loanAmount, this.state.FormData.ltv);
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        loanAmount,
        propertyValue,
      }
    }));
  }

  onChangePropertyValue(event) {
    const propertyValue = +event.target.value;
    const ltv = ltvCalculate(this.state.FormData.loanAmount, propertyValue);
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        propertyValue,
        ltv,
      }
    }));
  }

  onChangeLoanTerm(event) {
    const loanTerm = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        loanTerm,
      }
    }));
  }

  onChangeCashOut(event) {
    const cashOut = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        cashOut,
      }
    }));
  }

  onChangeZipcode(event) {
    const zipCode = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        zipCode,
      }
    }));
  }

  onChangeCreditScore(event) {
    const creditScore = +event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        creditScore,
      }
    }));
  }

  onChangePropertyType(event) {
    const propertyType = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        propertyType,
      }
    }));
  }

  onChangePropertyUsage(event) {
    const propertyUsage = event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        propertyUsage,
      }
    }));
  }

  onChangeLockDays(event) {
    const lockDays = +event.target.value;
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        lockDays
      }
    }));
  }

  onChangeLoanType(loanType) {
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        loanType,
      }
    }));
  }

  onChangeLTV(event) {
    const ltv = +event.target.value;
    const propertyValue = propertyValueCalculate(this.state.FormData.loanAmount, ltv);
    this.setState(prevState => ({
      FormData: {
        ...prevState.FormData,
        propertyValue,
        ltv,
      }
    }));
  }

  getDefaultLoanType(loanType) {
    if(this.state.FormData.loanType === null || this.state.FormData.loanType === 'null') {
      this.setState(prevState => ({
        FormData: {
          ...prevState.FormData,
          loanType,
        }
      }));
    }
  }

  getDefaultTestSuite(testSuite) {
    this.setState(prevState => ({
      ...prevState,
      testSuite,
    }));
  }

  onCancel() {
    this.props.onClose();
  }

  handleSubmitForm(event) {
    event.preventDefault();
    if(this.state.isEdit) {
      this.props.updateRateTestCase(this.props.testCaseId, { scenario: this.state.scenario, params: this.state.FormData }, this.props);
    } else {
      this.props.createRateTestCase(this.state.testSuite, this.state.scenario, this.state.FormData, this.props);
    }
    this.props.onClose();
  }

  renderFormData() {
    let result;
    const title = (this.state.isEdit) ? 'Edit' : 'Create new test case';
    const scenarioInput = (
      <ScenarioInput
        value={this.state.scenario} 
        onChange={this.onChangeScenario}
      />
    );
    const testSuiteSelectBox = (
      <TestSuiteSelectBox
        getDefaultValue={this.getDefaultTestSuite}
        value={this.state.testSuite}
        onChange={this.onChangeTestSuite}
      />
    );
    const testSuiteRow = (this.state.isEdit) ? (
      <Row>
        <Col>
          {scenarioInput}
        </Col>
      </Row>
    ) : (
      <Row>
        <Col>
          {testSuiteSelectBox}
        </Col>
        <Col>
          {scenarioInput}
        </Col>
      </Row>
    );
    result = (
      <Card elevation={0}>
        <CardHeader
          title={
            <h5 className="title">{title}</h5>
          }
        />
        <CardContent>
          <Form onSubmit={this.handleSubmitForm}>
            {testSuiteRow}
            <Row>
              <Col>
                <LoanPurposeSelectBox 
                  value={this.state.FormData.loanPurpose} 
                  onChange={this.onChangeLoanPurpose}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LoanAmountInput 
                  value={this.state.FormData.loanAmount} 
                  onChange={this.onChangeLoanAmount}
                />
              </Col>
              <Col>
                <LTVInput
                  value={this.state.FormData.ltv} 
                  onChange={this.onChangeLTV}
                />
              </Col>
              <Col>
                <PropertyValueInput 
                  value={this.state.FormData.propertyValue} 
                  onChange={this.onChangePropertyValue}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LoanTermSelectBox 
                  value={this.state.FormData.loanTerm}
                  onChange={this.onChangeLoanTerm}
                />
              </Col>
              <Col>
                <CashOutSelectBox 
                  value={this.state.FormData.cashOut}
                  onChange={this.onChangeCashOut}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ZipcodeInput 
                  value={this.state.FormData.zipCode}
                  onChange={this.onChangeZipcode}
                />
              </Col>
              <Col>
                <CreditScoreInput 
                  value={this.state.FormData.creditScore}
                  onChange={this.onChangeCreditScore}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <PropertyTypeSelectBox 
                  value={this.state.FormData.propertyType}
                  onChange={this.onChangePropertyType}
                />
              </Col>
              <Col>
                <PropertyUsageSelectBox 
                  value={this.state.FormData.propertyUsage}
                  onChange={this.onChangePropertyUsage}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LoanTypeWhitoutAllSelectBox 
                  getDefaultValue={this.getDefaultLoanType}
                  value={this.state.FormData.loanType}
                  onChange={this.onChangeLoanType}
                />
              </Col>
              <Col>
                <LockDaysSelectBox 
                  value={this.state.FormData.lockDays}
                  onChange={this.onChangeLockDays}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <hr></hr>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  size="sm" 
                  className="mr-2"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
                <Button 
                  color="primary" 
                  size="sm" 
                  type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </CardContent>
      </Card>
    );
    return result;
  }

  render() {
    return (
      <Fragment>
        {this.props.loading ? <Spinner/> : null}
        {this.renderFormData()}
      </Fragment>
    );
  }
  
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loadingDialog,
    rateTestingDetails: state.testing.rateTesting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRateTestCase: (testSuite, scenario, testCase, ownProps) => dispatch(createRateTestCase(testSuite, scenario, testCase, ownProps)),
    updateRateTestCase: (testCaseId, testCase, ownProps) => dispatch(updateRateTestCase(testCaseId, testCase, ownProps)),
    getTestingRate: (testCaseId) => dispatch(getTestingRate(testCaseId)),
  };
};

TestingRateForm.propTypes = {
  loading: PropTypes.bool,
  createRateTestCase: PropTypes.func,
  getTestingRate: PropTypes.func,
  updateRateTestCase: PropTypes.func,
  onClose: PropTypes.func,
  parentState: PropTypes.object,
  parentProps: PropTypes.object,
  rateTestingDetails: PropTypes.object,
  testCaseId: PropTypes.string,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestingRateForm));
