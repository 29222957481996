import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import { getConditions } from '../../actions/metadata.action';
import ConditionAddItem from './AddItem';
import { conditionParse, conditionFormat } from './../../utils';
import CancelIcon from '@material-ui/icons/Cancel';

const style = {
  conditionWrapper: {
    display: 'table',
    width: '100%',
    height: '100%',
  },
  conditionInput: {
    width: '95%',
    float: 'left',
  },
  addBtn: {
    width: '5%',
    float: 'left',
    cursor: 'pointer',
    fontSize: '32px',
  }
};

class ConditionInput extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      conditions: [],
      selectedConditions: [],
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleConditionAdded = this.handleConditionAdded.bind(this);
    this.onClickOpenDialog = this.onClickOpenDialog.bind(this);
    this.initConditions = this.initConditions.bind(this);
  }

  componentDidMount() {
    if(!this.props.conditions.length) {
      this.props.getConditions();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.selectedConditions !== this.state.selectedConditions) {
      this.props.onSelectedConditionFetched(conditionFormat(this.state.selectedConditions));
    }
    if(prevProps.value !== this.props.value && !this.state.conditions.length && !this.state.selectedConditions.length) {
      this.initConditions();
    }
  }

  initConditions() {
    const value = this.props.value;
    if(value && Object.keys(value).length) {
      if(Object.prototype.hasOwnProperty.call(value, 'logical')
      && Object.prototype.hasOwnProperty.call(value, 'rules')
      && value.rules.length) {
        this.setState(prevState => ({
          ...prevState,
          conditions: value.rules,
          selectedConditions: value.rules
        }));
      } else {
        this.setState(prevState => {
          return ({
            conditions: [...prevState.conditions, value],
            selectedConditions: [...prevState.selectedConditions, value]
          });
        });
      }
    }
  }

  onClickOpenDialog() {
    this.setState({
      openDialog: true,
    });
  }

  handleCloseDialog() {
    this.setState({
      openDialog: false,
    });
  }

  handleConditionAdded(condition) {
    this.setState(prevState => {
      return ({
        conditions: [...prevState.conditions, condition],
        selectedConditions: [...prevState.selectedConditions, condition]
      });
    });
  }

  handleDelete(indexItem) {
    const selectedConditions = this.state.selectedConditions;
    selectedConditions.splice(indexItem, 1);
    this.setState({
      selectedConditions
    });
  }

  handleSelected(selectedConditions) {
    this.setState({
      selectedConditions
    });
  }

  renderConditionInput() {
    const { classes } = this.props;
    const conditions = this.state.conditions;
    const selectedConditions = this.state.selectedConditions;
    return (
      <div className={classes.conditionWrapper}>
        <Autocomplete
          multiple
          className={classes.conditionInput}
          size="small"
          id={`conditions-${Date.now()}`}
          onChange={(event, value) => this.handleSelected(value)}
          value={selectedConditions}
          options={conditions}
          getOptionLabel={(option) => conditionParse(option, this.props.conditions)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return (
                <Chip
                  key={index}
                  {...getTagProps({ index })}
                  size="small"
                  label={conditionParse(option, this.props.conditions)}
                  clickable
                  onDelete={() => this.handleDelete(index)}
                  deleteIcon={<CancelIcon />}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
        <AddBoxIcon color="primary" className={classes.addBtn} onClick={() => this.onClickOpenDialog()} />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConditionInput()}
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            <ConditionAddItem conditions={this.props.conditions} onConditionFetched={this.handleConditionAdded} onClose={this.handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    conditions: state.metadata.conditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: () => dispatch(getConditions())
  };
};

ConditionInput.propTypes = {
  classes: PropTypes.object,
  conditions: PropTypes.array,
  condition: PropTypes.object,
  value: PropTypes.object,
  getConditions: PropTypes.func,
  onConditionFetched: PropTypes.func,
  onSelectedConditionFetched: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ConditionInput));
