import React from 'react';
import { Form } from 'react-bootstrap';

class PropertyValueInput extends React.Component {
  
  renderPropertyValueInput(props) {
    let result;
    result = (
      <Form.Group controlId="formPropertyValue">
        <Form.Label>Property Value</Form.Label>
        <Form.Control size="sm" type="text" {...props}/>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderPropertyValueInput(this.props);
  }
}


export default PropertyValueInput;

