import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Wrapper from '../../../components/Wrapper';
import PageTitle from '../../../components/PageTitle';

import Image from 'react-bootstrap/Image';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import './style.scss';

import { getLenders, updatePublishable } from './../../../actions';
import noImageURL from '../../../assets/images/no-image.png';

const style = ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 5,
    textAlign: 'center',
    borderRadius: 2,
  },
});

class LenderListing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50,
      searchingKey: '',
      type: 'Local',
      lenders: {},
      totalLenders: 0,
    };
  }

  componentDidMount() {
    this.getLenders();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.lenders !== this.props.lenders) {
      const lenders = this.props.lenders.docs;
      const totalLenders = this.props.lenders.totalDocs;
      this.setState(prevState => ({
        ...prevState,
        lenders,
        totalLenders,
      }));
    }
    if(prevProps.updatedLender !== this.props.updatedLender) {
      this.getLenders();
    }
  }

  getLenders = () => {
    this.props.getLenders(this.state.searchingKey, 1, this.state.rowsPerPage, this.state.type);
  }

  handlePublishableChange = (lenderId, isPublishable) => {
    this.props.updatePublishable(lenderId, isPublishable);
  }

  getLenderTable = () => {
    const { classes } = this.props;
    let result;
    const { lenders, totalLenders} = this.state;
    if(lenders && totalLenders > 0) {
      result = (
        <div className={classes.root}>
          <Grid container spacing={2}>
            {lenders.map((lender) => {
              const imageURL = (lender.image) ? lender.image : noImageURL;
              const lenderType = lender.type;
              const detailURI = (lenderType === 'Local') ? `/admin/lender/local/${lender._id}` : `/admin/lender/thirdparty/${lender._id}/`;
              const contentContent = <Grid item xs={6} sm={3} key={lender._id}>
                <Paper className={classes.paper}>
                  <div className="lender-container">
                    <div className="lender-logo">
                      <Image src={imageURL} />
                    </div>
                    <Switch
                      checked={lender.isPublishable}
                      onChange={() => this.handlePublishableChange(lender._id, !lender.isPublishable)}
                      color="primary"
                      name="isPublishable"
                    />
                    <Link to={detailURI} className="lender-name">
                      <h6>{lender.name}</h6>
                    </Link>
                  </div>
                </Paper>
              </Grid>;
              return contentContent;
            })}
          </Grid>
        </div>
      );
    }
    return result;
  }

  render() {
    return (
      <div>
        <PageTitle title='Lender' />
        <Wrapper>
          {this.getLenderTable()}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lenders: state.lender.lenders,
    updatedLender: state.lender.updatedLender,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLenders: (searchingKey, pageNumber, pageSize, type) => dispatch(getLenders(searchingKey, pageNumber, pageSize, type)),
    updatePublishable: (lenderId, isPublishable) => dispatch(updatePublishable(lenderId, isPublishable))
  };
};

LenderListing.propTypes = {
  classes: PropTypes.object,
  lenders: PropTypes.any,
  getLenders: PropTypes.func,
  updatePublishable: PropTypes.func,
  updatedLender: PropTypes.any
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(LenderListing));