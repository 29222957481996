import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import SignInForm from './../../../components/SignIn/Form';
import Image from 'react-bootstrap/Image';
import Logo from '../../../assets/images/logo.png';
import { logout } from './../../../actions';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.props.logout();
  }

  render() {
    return (
      <div>
        <Image src={Logo} className="logo" />
        <h3 className="form-title">Login to Your Account</h3>
        <SignInForm />
      </div>
    );
  }
}

const mapStateToProps = {
  logout: logout
};

SignUp.propTypes = {
  logout: PropTypes.func, 
};

export default withRouter(connect(null, mapStateToProps)(SignUp));