import React from 'react';
import PropTypes from 'prop-types';
import Assumptions from './../Assumptions/Form';
import Container from '@material-ui/core/Container';

class PortalSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.handleAssumptionChange = this.handleAssumptionChange.bind(this);
  }

  handleAssumptionChange(assumptions) {
    this.props.onAssumptionFetched(assumptions);
  }

  render() {
    return (
      <Container className="sidebar-wrapper">
        <Assumptions onAssumptionFetched={this.handleAssumptionChange}/>
      </Container>
    );
  }
}
  
PortalSidebar.propTypes = {
  onAssumptionFetched: PropTypes.func,
};

export default PortalSidebar;