import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_DIALOG_SPINNER,
  HIDE_DIALOG_SPINNER,
} from '../actions/types';

const INITIAL_STATE = {
  loading: false,
  loadingDialog: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case SHOW_DIALOG_SPINNER:
      return {
        ...state,
        loadingDialog: true,
      };
    case HIDE_DIALOG_SPINNER:
      return {
        ...state,
        loadingDialog: false,
      };
    default:
      return state;
  }
};
