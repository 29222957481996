import React from 'react';
import { Form } from 'react-bootstrap';

class LTVInput extends React.Component {
  
  renderLTVInput(props) {
    let result;
    result = (
      <Form.Group controlId="formLTV">
        <Form.Label>LTV</Form.Label>
        <Form.Control size="sm" type="text" {...props}/>
      </Form.Group>
    );
    return result;
  }

  render() {
    return this.renderLTVInput(this.props);
  }
}


export default LTVInput;

