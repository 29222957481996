import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const style = ({
  addLookupRateBtn: {
    marginTop: 25,
  },
  tableHeadCell: {
    backgroundColor: 'rgba(9, 57, 89, 0.12)',
  },
  tableBodyCell: {
    borderBottom: 0,
  }
});

class AdjustmentTableRuleAddLookupRate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      valueType: this.props.valueType,
      addForm: {
        from: null,
        to: null,
        value: null,
      },
    };
    this.onChangeFrom = this.onChangeFrom.bind(this);
    this.onChangeTo = this.onChangeTo.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onClickAddValues = this.onClickAddValues.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value && this.props.value.length) {
      this.setState(prevState => ({
        ...prevState,
        values: this.props.value
      }));
    }
    if(prevProps.valueType !== this.props.valueType) {
      this.setState(prevState => ({
        ...prevState,
        valueType: this.props.valueType
      }));
    }
  }

  onChangeFrom(event) {
    const from = +event.target.value;
    this.setState(prevState => ({
      addForm: {
        ...prevState.addForm,
        from
      }
    }));
  }

  onChangeTo(event) {
    const to = +event.target.value;
    this.setState(prevState => ({
      addForm: {
        ...prevState.addForm,
        to
      }
    }));
  }

  onChangeValue(event) {
    const value = +event.target.value;
    this.setState(prevState => ({
      addForm: {
        ...prevState.addForm,
        value
      }
    }));
  }

  onClickAddValues() {
    const lookupRateItem = this.state.addForm;
    const isValid = Object.keys(lookupRateItem).map(function (key) { 
      return lookupRateItem[key]; 
    }).every(x => x !== null); 
    if(isValid) {
      this.setState(prevState => ({
        values: [...prevState.values, this.state.addForm]
      }), () => this.props.onLookupRateFetched(this.state.values));
    }
  }

  renderAddLookupRateForm() {
    const { classes } = this.props;
    const result = (
      <Row>
        <Col>
          <Form.Group controlId={`form-lookup-rate-${Date.now()}-from`}>
            <Form.Label>From</Form.Label>
            <Form.Control size="sm" type="text" onChange={this.onChangeFrom}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`form-lookup-rate-${Date.now()}-to`}>
            <Form.Label>To</Form.Label>
            <Form.Control size="sm" type="text" onChange={this.onChangeTo}/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={`form-lookup-rate-${Date.now()}-rate`}>
            <Form.Label>Value</Form.Label>
            <Form.Control size="sm" type="text" onChange={this.onChangeValue}/>
          </Form.Group>
        </Col>
        <Col>
          <Button 
            className={classes.addLookupRateBtn}
            color="primary" 
            size="sm" 
            type="button"
            onClick={this.onClickAddValues}>Add
          </Button>
        </Col>
      </Row>
    );
    return result;
  }

  renderValuesTable() {
    const { classes } = this.props;
    let result = '';
    const values = this.state.values;
    if(values && values.length) {
      const lookupHeader = values.map(lookupItem => {
        let header = `${lookupItem.from} - ${lookupItem.to}`;
        if(!lookupItem.from && lookupItem.to) {
          header = `<= ${lookupItem.to}`;
        } else if(lookupItem.from && !lookupItem.to) {
          header = `>= ${lookupItem.from}`;
        }
        return header;
      });
      const valueType = (this.state.valueType === 'Percentage') ? '%' : '$';
      result = (
        <TableContainer component={Paper} square={true} elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell} style={{ width: 50 }}>LTV({valueType})</TableCell>
                {lookupHeader.map((header, headerIndex) => (
                  <TableCell key={headerIndex} className={classes.tableHeadCell}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableBodyCell}></TableCell>
                {values.map((value, index) => {
                  const valueFormated = (this.state.valueType === 'Percentage' && value.value >= 1000) ? 'N/A' : value.value;
                  return (
                    <TableCell key={index} className={classes.tableBodyCell}>{valueFormated}</TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return result;
  }

  render() {
    return (
      <div>
        {this.renderAddLookupRateForm()}
        {this.renderValuesTable()}
      </div>
    );
  }
}

AdjustmentTableRuleAddLookupRate.propTypes = {
  classes: PropTypes.object,
  onLookupRateFetched: PropTypes.func,
  value: PropTypes.array,
  valueType: PropTypes.string,
};


export default withStyles(style)(AdjustmentTableRuleAddLookupRate);