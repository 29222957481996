import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Spinner from '../../../components/Shared/Spinner';
import ConditionView from '../../../components/Condition/View';
import { getAdjustmentDetails } from './../../../actions';

const style = ({
  paperRoot: {
    padding: 10,
    marginTop: 10,
  },
  tableHeadCell: {
    backgroundColor: 'rgba(9, 57, 89, 0.12)',
  },
  tableBodyCell: {
    borderBottom: 0,
  }
});

class AdjustmentDetails extends Component {

  componentDidMount() {
    this.props.getAdjustmentDetails(this.props.adjustmentId, this.props.objectType);
  }

  renderHeader = () => {
    let result;
    if(this.props.adjustment) {
      const adjustment = this.props.adjustment;
      const adjustmentTableName = adjustment.name;
      const condition = adjustment.condition;
      const adjustmentCondition = (condition && Object.keys(condition).length) ?(<ConditionView condition={condition} />) : '';
      result = (
        <div>
          <h5>{adjustmentTableName}</h5>
          {adjustmentCondition}
        </div>
      );
    }
    return result;
  }

  renderRules = () => {
    let result;
    const { classes } = this.props;
    if(this.props.adjustment) {
      const adjustment = this.props.adjustment;
      const rules = adjustment.rules;
      if(rules && rules.length) {
        result = (
          <div>
            {rules.map((rule, ruleIndex) => {
              const values = rule.values;
              const lookupHeader = values.map(lookupItem => {
                let header = `${lookupItem.from} - ${lookupItem.to}`;
                if(!lookupItem.from && lookupItem.to) {
                  header = `<= ${lookupItem.to}`;
                } else if(lookupItem.from && !lookupItem.to) {
                  header = `>= ${lookupItem.from}`;
                }
                return header;
              });
              const ruleName = (rule.name) ? (<h6>{rule.name}</h6>) : '';
              const valueType = (rule.valueType === 'Percentage') ? '%' : '$';
              return (
                <Paper key={ruleIndex} className={classes.paperRoot} variant="outlined" >
                  {ruleName}
                  <ConditionView condition={rule.condition} />
                  <TableContainer component={Paper} square={true} elevation={0}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHeadCell}>
                            {this.props.adjustment.lookupParam}
                            ({valueType})
                          </TableCell>
                          {lookupHeader.map((header, headerIndex) => (
                            <TableCell key={headerIndex} className={classes.tableHeadCell}>{header}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tableBodyCell}></TableCell>
                          {values.map((value, index) => {
                            const valueFormated = (value.value === 1000) ? 'N/A' : value.value;
                            return (
                              <TableCell key={index} className={classes.tableBodyCell}>{valueFormated}</TableCell>
                            );
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              );
            })}
          </div>
        );
      }
    }
    return result;
  }

  render() {
    return (
      <Card elevation={0}>
        <CardContent>
          {this.props.loading ? <Spinner/> : null}
          {this.renderHeader()}
          {this.renderRules()}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.spinner.loadingDialog,
    adjustment: state.adjustment.adjustment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdjustmentDetails: (adjustmentId, objectType) => dispatch(getAdjustmentDetails(adjustmentId, objectType))
  };
};

AdjustmentDetails.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object,
  adjustmentId: PropTypes.string,
  getAdjustmentDetails: PropTypes.func,
  adjustment: PropTypes.object,
  adjustmentType: PropTypes.string,
  objectType: PropTypes.string,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(AdjustmentDetails));
