import {
  GET_RATETESTINGS,
  GET_RATETESTING,
  RUN_RATETESTING,
  GET_TESTSUITE,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_DIALOG_SPINNER,
  HIDE_DIALOG_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { TestingService } from './../services';

export const getTestingRates = () => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    TestingService.getTestingRates().then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_RATETESTINGS,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getTestingRate = (testCaseId) => {
  return (dispatch) => {
    dispatch({type: SHOW_DIALOG_SPINNER});
    TestingService.getRateTestCase(testCaseId).then(response => {
      if (response.success) {
        dispatch({type: HIDE_DIALOG_SPINNER});
        dispatch({
          type: GET_RATETESTING,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_DIALOG_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_DIALOG_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getTestSuites = () => {
  return (dispatch) => {
    TestingService.getTestSuites().then(response => {
      if (response.success) {
        dispatch({
          type: GET_TESTSUITE,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const runTestingRate = (params) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    TestingService.runTestingRate(params).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: RUN_RATETESTING,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const createRateTestCase = (testSuite, scenario, testCase, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    TestingService.createRateTestCase(testSuite, scenario, testCase, ownProps).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        const parentProps = ownProps.parentProps;
        parentProps.getTestingRates();
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const updateRateTestCase = (testCaseId, testCase, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    TestingService.updateeRateTestCase(testCaseId, testCase).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_SPINNER});
        const parentProps = ownProps.parentProps;
        parentProps.getTestingRates();
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const deleteRateTestCase = (testSuite, testCase, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    TestingService.deleteRateTestCase(testSuite, testCase, ownProps).then(response => {
      if (response.status === 200) {
        dispatch({type: HIDE_SPINNER});
        ownProps.getTestingRates();
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};