import {
  GET_RATE,
  GET_RATERESULT
} from '../actions/types';

const INITIAL_STATE = {
  rate: {},
  result: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_RATE:
      return {
        ...state,
        rate: action.data
      };
    case GET_RATERESULT:
      return {
        ...state,
        result: action.data
      };
    default:
      return state;
  }
};