import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

class ConditionView extends Component {

  renderConditionItem(condition, key = 1, logical = 'AND', length = 1) {
    const param = condition.param;
    const operator = condition.operator;
    const value = condition.value;
    const label = `${param} ${operator} ${value}`;
    return (
      <Fragment key={key}>
        <Chip label={label} color="primary" size="small" />
        {(key !== length) ? <span>{logical}</span> : null}
      </Fragment>
    );
  }
  
  renderCondition(){
    let result = '';
    const condition = this.props.condition;
    if (condition && Object.keys(condition).length) {
      if(Object.prototype.hasOwnProperty.call(condition, 'logical')
      && Object.prototype.hasOwnProperty.call(condition, 'rules')
      && condition.rules.length) {
        const rulesLength = condition.rules.length;
        const logical = condition.logical;
        const conditions = condition.rules.map((condition, index) => this.renderConditionItem(condition, index + 1, logical, rulesLength));
        result = conditions;
      } else {
        result = this.renderConditionItem(condition);
      }
    }
    return result;
  }

  render() {
    return this.renderCondition();
  }
}

ConditionView.propTypes = {
  condition: PropTypes.object,
};

export default ConditionView;