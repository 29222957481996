import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getLoanTypes } from '../../actions';

const style = {
  formControl: {
    minWidth: 120,
    marginRight: 10,
  },
};

class LoanTypeSelectBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loanTypeId: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getLoanTypes();
  }

  handleChange(event){
    const selectedValue = event.target.value;
    this.setState({
      loanTypeId: selectedValue
    });
    if (this.props.onChange) {
      this.props.onChange((selectedValue === 'all') ? '' : selectedValue);
    }
  }

  renderLoanTypeSelectBox() {
    const { classes } = this.props;
    let result;
    if(this.props.loanTypes) {
      const loanTypes = (this.props.loanTypes) ? this.props.loanTypes : [];
      result = (
        <FormControl className={classes.formControl}>
          <InputLabel id="loan-type-select-label">Loan type</InputLabel>
          <Select
            labelId="loan-type-select-label"
            id="loan-type-select"
            value={this.state.loanTypeId}
            onChange={this.handleChange}
          >
            <MenuItem value='all'>All</MenuItem>
            {(loanTypes.length) ? loanTypes.map((loantype, index) => (
              <MenuItem key={index} value={loantype._id}>{loantype.name}</MenuItem>
            )) : <MenuItem></MenuItem>}
          </Select>
        </FormControl>
      );
    }
    return result;
  }

  render() {
    return this.renderLoanTypeSelectBox();
  }
}

const mapStateToProps = state => {
  return {
    loanTypes: state.loanType.loanTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoanTypes: () => dispatch(getLoanTypes())
  };
};

LoanTypeSelectBox.propTypes = {
  classes: PropTypes.object,
  loanTypes: PropTypes.any,
  getLoanTypes: PropTypes.func,
  onChange: PropTypes.func,
};

export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(LoanTypeSelectBox));

