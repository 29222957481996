import {
  GET_LENDER,
  GET_LENDERS,
  GET_ALLLENDER,
  GET_ALL_PUBLISHED_LENDERS,
  UPDATE_PUBLISHED_SUCCESS,
  GET_RATEOFLENDER,
  GET_ADJUSTMENTOFLENDER,
  GET_RATEFILES,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from './types';
import { showErrorAlert } from './alert.action';
import { LenderService, FileService } from './../services';
  
export const getAllLender = () => {
  return (dispatch) => {
    LenderService.getAllLender().then(response => {
      if (response.success) {
        dispatch({
          type: GET_ALLLENDER,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getAllLenderPublished = () => {
  return (dispatch) => {
    LenderService.getAllLenderPublished().then(response => {
      if (response.success) {
        dispatch({
          type: GET_ALL_PUBLISHED_LENDERS,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const updatePublishable = (lenderId, isPublishable) => {
  return (dispatch) => {
    LenderService.updatePublishable(lenderId, isPublishable).then(response => {
      if (response.status === 200) {
        dispatch({
          type: UPDATE_PUBLISHED_SUCCESS,
          data: response.data.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getLenders = (searchingKey, pageNumber, pageSize, type) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    LenderService.getLenderListing(searchingKey, pageNumber, pageSize, type).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_LENDERS,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getLenderInfo = (lenderId) => {
  return (dispatch) => {
    LenderService.getLenderInfo(lenderId).then(response => {
      if (response.success) {
        dispatch({
          type: GET_LENDER,
          data: response.data
        });
      } else {
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getRateTablesOfLender = (lenderId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    LenderService.getRateTablesOfLender(lenderId, loanTypeId, searchingKey, pageNumber, pageSize, orderBy, order).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_RATEOFLENDER,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getAdjustmentTablesOfLender = (lenderId, searchingKey, type, pageNumber, pageSize, orderBy, order) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    LenderService.getAdjustmentTablesOfLender(lenderId, searchingKey, type, pageNumber, pageSize, orderBy, order).then(response => {
      if (response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_ADJUSTMENTOFLENDER,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const getRateFilesOfLender = (lenderId) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    FileService.getRateFilesOfLender(lenderId).then(response => {
      if (response && response.success) {
        dispatch({type: HIDE_SPINNER});
        dispatch({
          type: GET_RATEFILES,
          data: response.data
        });
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const uploadRateFileOfLender = (lenderId, file, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    FileService.uploadRateFileOfLender(lenderId, file).then(response => {
      if (response && response.success) {
        dispatch({type: HIDE_SPINNER});
        ownProps.getRateFilesOfLender(lenderId);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};

export const uploadMetadataFileOfLender = (lenderId, file, ownProps) => {
  return (dispatch) => {
    dispatch({type: SHOW_SPINNER});
    FileService.uploadMetadataFileOfLender(lenderId, file).then(response => {
      if (response && response.success) {
        dispatch({type: HIDE_SPINNER});
        ownProps.getRateFilesOfLender(lenderId);
      } else {
        dispatch({type: HIDE_SPINNER});
        showErrorAlert(dispatch, 'Failed operation');
      }
    }, error => {
      dispatch({type: HIDE_SPINNER});
      showErrorAlert(dispatch, 'Failed operation' + error.toString());
    });
  };
};